import {
  RecoverPasswordService,
  ResetPasswordService,
  SignInService,
  SignUpWithMailData,
} from "../../types/auth";
import { AuthApi } from "./AuthApi";

const SignIn = async ({ email, password }: SignInService) => {
  const authData = await AuthApi.SignIn({ email, password });
  return authData;
};

const SignUp = async ({
  name,
  email,
  password,
  password_confirmation,
}: SignUpWithMailData) => {
  const response = await AuthApi.SignUp({
    name,
    email,
    password,
    password_confirmation,
  });

  return response;
};

const UpdateMail = async ({ email, password }: SignInService) => {
  const response = await AuthApi.UpdateMail({ email, password });
  return response;
};

const RecoverPassword = async ({ email }: RecoverPasswordService) => {
  const response = await AuthApi.RecoverPassword({ email });
  return response;
};

const CreatePassword = async ({ password, password_confirmation }: any) => {
  const response = await AuthApi.CreatePassword({
    password,
    password_confirmation,
  });
  return response;
};

const ResetPassword = async ({
  password,
  password_confirmation,
  token,
}: ResetPasswordService) => {
  const response = await AuthApi.ResetPassword({
    password,
    password_confirmation,
    token,
  });
  return response;
};

export const AuthService = {
  SignIn,
  SignUp,
  UpdateMail,
  RecoverPassword,
  ResetPassword,
  CreatePassword,
};
