import styled from "styled-components";
import AppInput from "../../components/Input";
import AppButton from "../../components/Button";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 4rem);
  label {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #7c878d;
  }

  > span {
    font-style: normal;
    font-size: 1.5rem;
    line-height: 130%;
    text-align: center;
    margin: 2rem 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: 21.875rem;
  padding: 1rem;
`;

export const Input = styled(AppInput)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  padding: 1rem;
`;

export const Button = styled(AppButton)`
  color: #fff;
  width: 100%;
  height: fit-content;
  padding: 1rem;
  margin-top: 1rem;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;
