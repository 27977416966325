import React, { ButtonHTMLAttributes } from "react";

import { Container } from "./styles";
import ReactLoading from "react-loading";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: true | false;
  loadingColor?: string;
  icon?: true | false;
};

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  loadingColor,
  ...rest
}) => (
  <Container type="button" {...rest}>
    {loading ? (
      <ReactLoading
        type="bubbles"
        color={loadingColor ? loadingColor : "#fff"}
        height="46px"
        width="46px"
      />
    ) : (
      children
    )}
  </Container>
);

export default Button;
