import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Content = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
  }

  > header {
    margin-top: 15px;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 1.5rem;
    color: var(--title);
  }
`;
