import styled from "styled-components";
import AppInput from "../Input";
import AppButton from "../Button";

interface ContainerProps {
  MouseIsOver?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  display: flex;
  align-items: center;
  justify-items: space-between;
  background: var(--primary);
  padding: 8px;
  border-radius: 140px;
  transition: all 0.1s ease-in;
  overflow: hidden;
  max-width: ${({ MouseIsOver }) => (MouseIsOver ? "100%" : "4rem")};

  cursor: pointer;

  span {
    display: ${({ MouseIsOver }) => (MouseIsOver ? "block" : "none")};
    color: #fff;
    margin: 0 3px;
  }

  svg {
    margin: 0 3px;
  }
`;

export const HelpForm = styled.div`
  width: 450px;
  height: auto;
  background: var(--primary);
  margin-bottom: 0.5rem;
  border-radius: 0.75rem;
  padding: 1rem;

  h1 {
    text-align: center;
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  form {
    display: flex;
    flex-direction: column;

    > label {
      margin-top: 0.2rem;
      color: var(--gray-300);
    }
  }

  select {
    margin: 0.5rem 0;
    height: 2rem;
    border-radius: 4px;
  }

  textarea {
    border-radius: 4px;
    padding: 0.5rem;
    height: 6rem;
    margin-top: 0.25rem;
  }
`;

export const Input = styled(AppInput)`
  padding: 0.75rem;
`;

export const Button = styled(AppButton)`
  padding: 0.75rem;
  background: #fff;

  &:hover {
    filter: brightness(0.8);
  }
`;
