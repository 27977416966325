import styled, { css } from "styled-components";

export const Container = styled.div`
  margin-top: 2rem;
  width: 100%;
  /* min-width: 800px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

interface DocumentTitleProps {
  isOpen: boolean;
}

interface CardProps {
  isOpen: boolean;
  highlighted?: boolean;
}

export const Card = styled.div<CardProps>`
  width: 100%;
  display: flex;
  border-radius: 4px;
  transition: all 0.4s ease-in-out;
  height: ${({ isOpen }) => (isOpen ? "auto" : "4rem")};
  background: #fff;
  margin: 10px 0;
  align-items: ${({ isOpen }) => (isOpen ? "flex-start" : "center")};
  justify-content: flex-start;
  flex-direction: column;
  transition: all 0.3 ease-in-out;

  ${({ highlighted }) => css`
    /* box-shadow: ${highlighted
      ? "rgba(101, 15, 64, .4) 0px 0px 0px 3px"
      : "none"}; */
    box-shadow: ${highlighted
      ? "rgba(219, 159, 28,0.7) 0px 0px 0px 3px"
      : "none"};
  `}

  > header {
    margin-top: ${({ isOpen }) => (isOpen ? "10px" : "0")};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: ${({ isOpen }) => (isOpen ? "" : "100%")};

    p {
      margin-bottom: 1rem;
    }
  }

  svg {
    margin-right: 1rem;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: #d8d8d8;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  max-width: 80%;

  > p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: .8rem;
    line-height: 140%;
    color: #374954;
    margin-left: 1rem;
    margin-top: 4px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
`;

export const Content = styled.div``;

export const LoadingBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  margin-top: 5rem;
`;

export const DocumentTitle = styled.h1<DocumentTitleProps>`
  margin-left: 1rem;
  font-size: 1rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: ${({ isOpen }) => (isOpen ? "var(--primary)" : "#7c878d")};
`;

interface DocumentStatusTextProps {
  color: string;
}

export const DocumentStatusBox = styled.div`
  display: flex;
  align-items: center;

  > span {
    margin-right: 0.5rem;
  }
`;

export const DocumentStatusText = styled.span<DocumentStatusTextProps>`
  color: ${({ color }) => color};
`;

export const SaibaMais = styled.div`
  color: #fff;
  background: var(--primary);
  border: 1px solid #d8d8d8;
  margin: 0 1rem;
  padding: 0.15rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;

  &:hover {
    filter: brightness(0.8);
  }

  > span {
    margin: 0 0.15rem;
    font-size: .7rem;
  }

  svg {
    margin: 0 0.15rem;
    color: #fff;
  }
`;
