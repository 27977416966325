import styled, { keyframes } from "styled-components";
import AppInput from "../../components/Input";
import AppButton from "../../components/Button";

import signUpBackground from "../../assets/sign-in-background.png";

export const Container = styled.div`
  height: calc(100vh - 4rem);
  display: flex;
  align-items: stretch;
  min-width: 800px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 700px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(0px);
  }
  to {
    opacity: 1;
    transform: translateX(50px);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${appearFromLeft} 0.7s;
  form {
    margin: 80px 0;
    width: 340px;
    display: flex;
    flex-direction: column;
    background: #fff;
    align-items: center;
    padding: 20px 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    h1 {
      margin-bottom: 24px;
    }

    > div {
      margin-top: 0.5rem;
    }
  }
  > a {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #30c5ff;
    cursor: pointer;

    &:hover {
      filter: brightness(0.5);
    }
    svg {
      margin-right: 8px;
    }
  }

  img {
    width: 300px;
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signUpBackground}) no-repeat center;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  img {
    height: 4.5rem;
    width: auto;
  }
`;

export const Input = styled(AppInput)`
  align-items: flex-start;
  padding: 0.75rem 1rem;
  background: #ffffff;
  width: 100%;
  border: 1px solid #aab6bc;
  box-sizing: border-box;
  border-radius: 4.8px;
`;

export const Button = styled(AppButton)`
  margin-top: 2rem;
  background: var(--primary);
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: none;
  width: 300px;
  height: 3rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #ffffff;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;
