import React from "react";
import { MessageText } from "../../../../../types/issue";

import { Container, Message } from "./styles";

interface ChatConversationProps {
  messages?: MessageText[];
  chatTitle: string;
}

const ChatConversation = ({ chatTitle, messages }: ChatConversationProps) => {
  return (
    <Container>
      <span>{chatTitle}</span>
      {messages?.map((message) => (
        <Message key={message?.id} isMine={message.reply}>
          <span>{message.issueText}</span>
        </Message>
      ))}
    </Container>
  );
};

export default ChatConversation;
