import React from "react";
import Topbar from "../../components/Topbar";
import history from "../../services/history";

import { Container } from "./styles";

const Contract: React.FC = () => {
  return (
    <>
      <Topbar showNotificationsBadge={false} />
      <Container>
        <button onClick={history.goBack}>voltar</button>
        Tela de Contratação
      </Container>
    </>
  );
};

export default Contract;
