import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import { BreadCrumbNavItem } from "../../components/BreadCrumb/types";
import Timeline from "../../components/Timeline";
import Topbar from "../../components/Topbar";
import history from "../../services/history";
import { CauseProps } from "../../types/cause";
import AccompanimentDetails from "./components/AccompanimentDetails";

import { Container, Content } from "./styles";

interface RouteParams {
  id?: string | undefined;
}

const CauseAccompaniment: React.FC = () => {
  const [detailedAcompaniment, setDetailedAcompaniment] = useState(null);
  let { id } = useParams<RouteParams>();
  const queryclient = useQueryClient();
  const causesData = queryclient.getQueryData<any>("CausesGetList");
  const cause: CauseProps = causesData?.data?.find(
    (cause: CauseProps) => cause?.id?.toString() === id
  );

  const breadCrumbNavItems: BreadCrumbNavItem[] = [
    { label: "Causa", url: `/cause/${id}` },
    { label: "Acompanhamento" },
  ];

  if (!id) {
    history.push("/causes");
  }

  return (
    <Container>
      <Topbar />
      <BreadCrumb navItems={breadCrumbNavItems} />
      <Content>
        {causesData && <header>{causesData[0]?.demand?.title}</header>}
        {detailedAcompaniment !== null ? (
          <AccompanimentDetails
            onBack={setDetailedAcompaniment}
            acompaniment={detailedAcompaniment}
          />
        ) : (
          <Timeline
            causeName={cause?.demand_code}
            onCardClick={setDetailedAcompaniment}
          />
        )}
      </Content>
    </Container>
  );
};

export default CauseAccompaniment;
