import { CauseStage, CauseProps } from "../../types/cause";
import { Sections } from "../../types/document";
import { CausesApi } from "./CausesApi";

const getCauses = async (): Promise<CauseProps[]> => {
  const { data } = await CausesApi.getCauses();
  return data;
};

const getCause = async (id: number): Promise<CauseProps> => {
  const { data } = await CausesApi.getCause(id);
  return data;
};

const getDocumentsOfCause = async (id: number): Promise<Sections> => {
  const { data } = await CausesApi.getDocumentsOfCause(id);
  return data;
};

export const getStageOfCause = async (
  cause_id: String,
  page?: string,
  items?: string
): Promise<CauseStage[]> => {
  const { data } = await CausesApi.getStageOfCause(cause_id, page, items);

  return data;
};
export const CausesService = {
  getCauses,
  getCause,
  getDocumentsOfCause,
  getStageOfCause,
};
