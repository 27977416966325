import styled, { css, keyframes } from "styled-components";

export const Container = styled.div`
  position: relative;
`;

interface NotificationProps {
  unRead: boolean;
}
interface NotificationListProps {
  visible: boolean;
}

interface BadgeProps {
  hasUnread: boolean;
}

export const shine = keyframes`
  0%   {background: #fff164;}
  50%  {background: var(--secondary);}
  100%  {background: #fff164;}
`;

export const Badge = styled.button<BadgeProps>`
  background: none;
  border: 0;
  border-radius: 100%;
  background: rgb(219, 159, 28, 0.3);
  padding: 0.25rem;
  position: relative;
  z-index: 0.8;

  &:hover {
    filter: brightness(0.9);
  }

  ${(props) =>
    props.hasUnread &&
    css`
      &:after {
        position: absolute;
        right: 0;
        top: 0;
        width: 8px;
        height: 8px;
        /* background: var(--secondary); */
        content: "";
        border-radius: 50%;
        z-index: 10;
        animation: ${shine} infinite 3s;
      }
    `}
`;

export const NotificationList = styled.div<NotificationListProps>`
  position: absolute;
  width: 400px;
  left: calc(50% - 205px);
  top: calc(100% + 25px);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  padding: 15px 5px;
  display: ${(props) => (props.visible ? "block" : "none")};
  &::before {
    content: "";
    position: absolute;
    left: calc(50% - 20px);
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid rgba(0, 0, 0, 0.6);
  }

  > footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 0.5rem 0 0;

    > a {
      text-decoration: none;
    }
    span {
      color: var(--secondary);
      font-weight: bold;
      transition: all 0.2s;
      cursor: pointer;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }
`;

export const Scroll = styled.div`
  max-height: 260px;
  padding: 5px 15px;
  overflow: auto;
`;

export const Notification = styled.div<NotificationProps>`
  color: white;
  margin-left: 5px;
  transition: all 0.2s ease-in-out;

  & + div {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  p {
    font-size: 13px;
    line-height: 18px;
    max-width: 60%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  time {
    display: block;
    font-size: 12px;
    opacity: 0.6;
    margin-bottom: 5px;
  }
  button {
    font-size: 12px;
    border: 0;
    background: none;
    transition: all 0.2s;
    color: var(--secondary);
    &:hover {
      filter: brightness(0.8);
    }
  }
  ${(props) =>
    props.unRead &&
    css`
      &::after {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #ff892e;
        border-radius: 50%;
        margin-left: 10px;
      }
    `}
`;

export const SpacedRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
