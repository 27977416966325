import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-width: 800px;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface RoundedTextProps {
  backgroundColor: string;
  color?: string;
}

export const RoundedText = styled.span<RoundedTextProps>`
  padding: 0.3rem 1rem;
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 1.5rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: ${({ color }) => (color ? color : "#ffff")};
  margin: 0px 0.4rem;
`;

export const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  > label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 23px;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: var(--title);
  }
  > span {
    margin-top: 0.25rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: right;
    color: #7c878d;
  }
`;

export const Row = styled.div`
  display: flex;
  > label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #7c878d;
  }
  > span {
    margin-top: 0.25rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: right;
    color: #7c878d;
  }
`;
