import {
  RecoverPasswordService,
  ResetPasswordService,
  SignInWithMailData,
  SignUpWithMailData,
} from "../../types/auth";
import api from "../api";

export const SignIn = async ({ email, password }: SignInWithMailData) => {
  const { data } = await api.post(`auth/sign_in`, {
    email,
    password,
  });

  api.defaults.headers.common["Authorization"] = data.access_token;
  localStorage.setItem("@biro:user", JSON.stringify(data.user));

  return data;
};

export const SignUp = async ({
  email,
  password,
  password_confirmation,
  name,
}: SignUpWithMailData) => {
  const { data } = await api.post(`client`, {
    email,
    password,
    password_confirmation,
    name,
  });

  return data;
};

export const RecoverPassword = async ({ email }: RecoverPasswordService) => {
  const { data } = await api.post(`password/forgot`, {
    email,
  });

  localStorage.setItem("@biro:user", JSON.stringify(data.user));
  return data;
};

export const ResetPassword = async ({
  password,
  password_confirmation,
  token,
}: ResetPasswordService) => {
  const { data } = await api.post(
    `password/reset`,
    {
      password,
      password_confirmation,
    },
    {
      headers: { Authorization: token },
    }
  );
  localStorage.setItem("@biro:user", JSON.stringify(data.user));
  return data;
};

export const CreatePassword = async ({
  password,
  password_confirmation,
}: any) => {
  const { data } = await api.post(`password/reset`, {
    password,
    password_confirmation,
    reset_paswword: false,
  });
  return data;
};

export const UpdateMail = async ({ email, password }: SignInWithMailData) => {
  const { data } = await api.patch(`client`, {
    email,
    password,
    confirm: password,
  });

  return data;
};

export const AuthApi = {
  SignIn,
  SignUp,
  UpdateMail,
  RecoverPassword,
  ResetPassword,
  CreatePassword,
};
