import React from "react";
import { useQuery } from "react-query";
import ReactLoading from "react-loading";
import { HiOutlineDocumentText } from "react-icons/hi";
import { VscOpenPreview } from "react-icons/vsc";
import history from "../../services/history";

import Topbar from "../../components/Topbar";
import Span from "../../components/ColoredSpan";
import BreadCrumb from "../../components/BreadCrumb";
import { CausesService } from "../../services/Causes/CausesService";
import {
  statusColors,
  statusTranslated,
  stepColors,
  stepTranslated,
} from "../../utils/formatSpanShadow";

import {
  AccompanimentButton,
  Cause,
  CauseDataBox,
  CauseDetailsBox,
  CauseNumberBox,
  CausesBox,
  Container,
  Content,
  DocumentationButton,
} from "./styles";
import UpdateMailModal from "../../components/UpdateMailModal";

const Causes: React.FC = () => {
  const { data, isLoading } = useQuery(
    "CausesGetList",
    CausesService.getCauses,
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        history.push("/documents");
      },
    }
  );

  if (isLoading) {
    return (
      <Container>
        <Topbar />
        <ReactLoading
          type="bubbles"
          color="var(--primary)"
          height="46px"
          width="46px"
        />
      </Container>
    );
  }

  if (data?.length === 0) {
    history.push("documents");
  }

  return (
    <Container>
      <Topbar />
      <BreadCrumb navItems={[]} />
      {data && (
        <header>
          <h1>
            Bem vindo,
            <span>{` ${data[0]?.client?.name}`}</span>
          </h1>
        </header>
      )}

      <Content>
        <h2>Suas causas: </h2>
        <CausesBox>
          {data?.map((cause) => (
            <Cause key={Number(cause?.id)}>
              <CauseDataBox to={`cause/${cause?.id}`}>
                <CauseNumberBox>
                  <label>Numero do processo:</label>
                  <p>{cause?.process_number ? cause?.process_number : "-"}</p>
                </CauseNumberBox>
                <CauseDetailsBox>
                  <div>
                    {cause?.demand?.title ? (
                      <>
                        <label>Causa: </label>
                        <p>{cause?.demand?.title}</p>
                      </>
                    ) : null}
                    {cause?.demand_code ? (
                      <>
                        <label>Codigo da ação: </label>
                        <p>{cause?.demand_code}</p>
                      </>
                    ) : null}
                    {cause?.demand?.defense ? (
                      <>
                        <label>Defesa: </label>
                        <p>{cause?.demand?.defense}</p>
                      </>
                    ) : null}
                  </div>
                  <aside>
                    {cause.step ? (
                      <Span color={stepColors[cause.step]}>
                        {stepTranslated[cause.step]}
                      </Span>
                    ) : null}
                    {cause.status ? (
                      <Span color={statusColors[cause.status]}>
                        {statusTranslated[cause.status]}
                      </Span>
                    ) : null}
                  </aside>
                </CauseDetailsBox>
              </CauseDataBox>
              {cause.stages.length > 0 ? (
                <AccompanimentButton to={`/cause/accompaniment/${cause.id}`}>
                  <VscOpenPreview size={30} />
                  Acompanhamento
                </AccompanimentButton>
              ) : null}

              {cause.step === "documentation" && (
                <DocumentationButton to={`/documents/${cause.id}`}>
                  <HiOutlineDocumentText size={30} /> Documentação
                </DocumentationButton>
              )}
            </Cause>
          ))}
        </CausesBox>
      </Content>
      <UpdateMailModal />
    </Container>
  );
};

export default Causes;
