import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ReactLoading from "react-loading";
// import { Link } from "react-router-dom";
import Topbar from "../../components/Topbar";
import { NotificationsService } from "../../services/Notifications/NotificationsService";
import { parseDate } from "../../utils/formatDate";
import {
  Notification,
  NotificationKindColors,
  NotificationKindTranslated,
} from "../../types/notification";

import {
  Container,
  Content,
  NotificationBox,
  NotificationList,
} from "./styles";
import ColoredSpan from "../../components/ColoredSpan";
import history from "../../services/history";

const Notifications: React.FC = () => {
  const queryClient = useQueryClient();

  const { data, isLoading: loading } = useQuery(
    "GetNotificationsList",
    NotificationsService.getNotifications
  );

  const { mutate } = useMutation(
    "MarkNotificationAsRead",
    (id: number) => NotificationsService.setNotificationRead(id),
    {
      onSuccess: () => {
        queryClient.refetchQueries("GetNotificationsList");
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  if (loading) {
    return (
      <Container>
        <Topbar />
        <ReactLoading
          type="bubbles"
          color="var(--secondary)"
          height="1.5rem"
          width="1.5rem"
        />
      </Container>
    );
  }

  const handleOnNotificationClick = (notification: Notification) => {
    mutate(notification.id);
    history.push(
      `/documents/${notification.cause.id}?notificated-doc=${notification.item_id}`
    );
  };

  return (
    <Container>
      <Topbar />
      <header>
        <div>
          <h1>Notificações</h1>
          <button>Marcar todos como lidos</button>
        </div>
      </header>
      <Content>
        <NotificationList>
          {data?.map((notification, index) => (
            <NotificationBox
              key={notification.id}
              index={index}
              unRead={!notification.read}
              onClick={() => handleOnNotificationClick(notification)}
            >
              <header>
                <div>
                  <time>{parseDate(notification.updated_at)}</time>
                  {!notification.read && <span>Não lida</span>}
                </div>
                <ColoredSpan color={NotificationKindColors[notification.kind]}>
                  {NotificationKindTranslated[notification.kind]}
                </ColoredSpan>
              </header>
              <p>{notification.body}</p>
            </NotificationBox>
          ))}
        </NotificationList>
      </Content>
    </Container>
  );
};

export default Notifications;

