import { Notification } from "../../types/notification";
import { NotificationsApi } from "./NotificationsApi";

const getNotifications = async (): Promise<Notification[]> => {
  const { data } = await NotificationsApi.getNotifications();
  return data;
};

const setNotificationRead = async (id: number): Promise<void> => {
  const response = await NotificationsApi.setNotificationRead(id);
  return response;
};

export const NotificationsService = {
  getNotifications,
  setNotificationRead,
};
