import { AnyMap } from "immer/dist/internal";
import { UploadImageApi } from "./UploadImageApi";

const uploadImage = async (
  formData: any,
  causeId: AnyMap,
  necessaryId: number
): Promise<any> => {
  const response = await UploadImageApi.uploadImage(
    formData,
    causeId,
    necessaryId
  );
  return response;
};

const deleteImage = async (imageId: number): Promise<any> => {
  const response = await UploadImageApi.deleteImage(imageId);
  return response;
};

export const UploadImageService = {
  uploadImage,
  deleteImage,
};
