import { CauseProps, GetCauseStageResponse } from "../../types/cause";
import api from "../api";

export const getCauses = async (): Promise<CauseProps> => {
  const { data } = await api.get<CauseProps>(`causes`);
  return data;
};

export const getCause = async (id: number): Promise<CauseProps> => {
  const { data } = await api.get<CauseProps>(`causes/${id}`);
  return data;
};

export const getDocumentsOfCause = async (id: number): Promise<any> => {
  const { data } = await api.get(`causes/${id}/documents`);
  return data;
};

export const getStageOfCause = async (
  cause_id: String,
  page?: string,
  items?: string
): Promise<GetCauseStageResponse> => {
  const { data } = await api.get(`causes/${cause_id}/stages`, {
    data: { page, items },
  });
  return data;
};

export const CausesApi = {
  getCause,
  getCauses,
  getDocumentsOfCause,
  getStageOfCause,
};
