import api from "../api";

export const uploadImage = async (
  file: FormData,
  causeId: any,
  necessaryId: any
): Promise<any> => {
  const { data } = await api.post<any>(`causes/${causeId}/documents`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

export const deleteImage = async (imageId: number): Promise<any> => {
  const { data } = await api.delete<any>(`/documents/${imageId}`);
  return data;
};

export const UploadImageApi = {
  uploadImage,
  deleteImage,
};
