import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background: var(--gray-200);
  width: 100%;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--gray-100);
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3rem;
  width: 80vw;

  a {
    display: flex;
    align-items: center;
    svg {
      color: var(--primary);
    }
  }

  span {
    color: var(--gray-700);
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
      text-decoration: underline;
    }
  }
`;
