import { Container, Content, Profile, Menu, StagingText } from "./styles";
import logoImg from "../../assets/logo.svg";
import { FiLock } from "react-icons/fi";
import NotificationsBadge from "../NotificationsBadge";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../store/modules/auth/actions";
import history from "../../services/history";

interface TopbarProps {
  showNotificationsBadge?: boolean;
}

const Topbar = ({ showNotificationsBadge = true }: TopbarProps) => {
  const signed = useSelector((state: any) => state.auth.signed);
  const dispatch = useDispatch();

  return (
    <Container>
      <Content>
        <Menu>
          <img
            src={logoImg}
            alt="Birô"
            onClick={() => history.push("/causes")}
          />
          {process.env.REACT_APP_ENVIRONMENT === "staging" ? (
            <StagingText>Staging</StagingText>
          ) : null}
        </Menu>
        <Profile>
          {signed && showNotificationsBadge && <NotificationsBadge />}
          <span>Ambiente Seguro</span>
          <FiLock size={24} color="var(--gray-200)" />
          {signed && <h3 onClick={() => dispatch(signOut())}>SAIR</h3>}
        </Profile>
      </Content>
    </Container>
  );
};

export default Topbar;
