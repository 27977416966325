import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  header {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    padding: 0.5rem;
    padding-left: 0;
    color: var(--secondary);
    transition: all 0.2s;

    &:hover {
      background-color: #f5f5f5;
      filter: brightness(0.8);
      border-radius: 4px;
    }
  }

  > div {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 1.5rem;
    color: var(--title);
  }
`;

export const Content = styled.main`
  display: flex;
  padding: 1rem;
  background: var(--gray-200);
`;
