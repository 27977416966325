import { CauseProps } from "../cause";
import { Client } from "../client";

export interface Notification {
  id: number;
  title: string;
  body: string;
  read: boolean;
  kind: NotificationKind;
  created_at: Date;
  updated_at: Date;
  item_type: string;
  item_id: number;
  client: Client;
  cause: CauseProps;
}

export interface GetNotificationsResponse {
  data: Notification[];
}

export enum NotificationKind {
  documentation = "documentation",
}

export const NotificationKindTranslated = {
  [NotificationKind.documentation]: "Documentação",
};

export const NotificationKindColors = {
  [NotificationKind.documentation]: "#ffe8d8",
};
