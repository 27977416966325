import styled, { css, keyframes } from "styled-components";
import { shine } from "../../components/NotificationsBadge/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 800px;
  > header {
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--gray-200);

    > div {
      width: 80vw;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > h1 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        margin-left: 5px;
      }

      > button {
        background: var(--secondary);
        border: 1px solid var(--gray-200);
        outline: 0;
        border-radius: 8px;
        padding: 8px;
        font-size: 12px;
        font-family: Roboto;
        font-weight: bold;
        color: var(--gray-800);

        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }
`;

export const Content = styled.div`
  width: 80vw;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

interface NotificationProps {
  unRead: boolean;
  index: number;
}

export const NotificationList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0;
  max-width: 80%;
`;

const popFromBottom = keyframes`
  0% {
    transform: translateY(50px) scale(0);
  }
  100% {
    transform: translateY(0px) scale(1);
  }
  `;

export const NotificationBox = styled.div<NotificationProps>`
  ${({ index }) => css`
    animation: ${popFromBottom} ${index / 4}s ease-in-out;
  `};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  border-top: 1px solid var(--gray-200);
  padding: 0.7rem 1rem;
  color: var(--gray-850);
  background: var(--gray-200);
  border-radius: 12px;
  margin: 0.5rem 0;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

  :first-child {
    border-top: none;
  }

  &:hover {
    filter: brightness(0.8);
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  > header div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    grid-gap: .5rem;

    span {
      font-weight: bold;
      font-size: .8rem;
      color: var(--secondary);
    }
  }

  p {
    margin: 0.5rem 0;
    font-size: 1rem;
    line-height: 18px;
  }
  time {
    display: block;
    font-size: 12px;
    color: var(--gray-500);
    /* ${(props: any) =>
      props.unRead &&
      css`
        &::after {
          content: "";
          display: inline-block;
          width: 10px;
          animation: ${shine} infinite 3s;
          height: 10px;
          background: #ff892e;
          border-radius: 50%;
          margin-left: 10px;
        }
      `} */
  }
  button {
    margin-top: 5px;
    font-size: 0.8rem;
    border: 0;
    background: none;
    transition: all 0.2s;
    color: var(--secondary);
    &:hover {
      filter: brightness(0.8);
    }
    ${(props: any) =>
      props.unRead &&
      css`
        &::after {
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          background: #ff892e;
          border-radius: 50%;
          margin-left: 10px;
        }
      `}
  }
  ${({ unRead }) =>
    unRead
      ? css`
          background: var(--gray-200);
        `
      : css`
          opacity: 0.3;
        `}
`;
