export interface MessageText {
  issueText: string;
  id: number;
  reply: boolean;
}

export interface Issue {
  id: number;
  title: string;
  lastReplyDate: Date;
  hasUnReadMessage: boolean;
  description: string;
  messages?: MessageText[];
  type: "Documentação" | "Processo" | "Revisão";
}

export enum IssueTypes {
  DOCUMENTACAO = "Documentação",
  PROCESSO = "Processo",
  REVISAO = "Revisão",
}

export const IssueTypescolors = {
  [IssueTypes.DOCUMENTACAO]: "#e0f0d6",
  [IssueTypes.PROCESSO]: "#4db6ac",
  [IssueTypes.REVISAO]: "#ffe8d8",
};

export interface IssueComponent {
  issue: Issue;
  onClick?: () => void;
}
