import { toast } from "react-toastify";
import { put } from "redux-saga/effects";
import { takeLatest, all } from "redux-saga/effects";
import api from "../../../services/api";
import history from "../../../services/history";
import { updateEmailProfile } from "../user/actions";
import { signInSucess, signOut as signOutAction } from "./actions";

export function* signIn({ payload }) {
  const { access_token, user, token, email } = payload;
  if (token) {
    api.defaults.headers.Authorization = token;
    try {
      const { data } = yield api.get("/client");
      localStorage.setItem("@biro:user", JSON.stringify(data?.data));
      yield put(signInSucess(token, data?.data));
      if (data.data.client_identity.ask_for_cpf) {
        return history.push("/checkcpf")
      }
      else {
        history.push("/causes");
      }
    } catch (err) {
      if (email) {
        yield put(updateEmailProfile({ email }));
      } else {
        yield put(signOutAction());
        toast.error(err?.response?.data?.token_invalid[0]);
      }
      history.push("/causes");
    }
  } else {
    localStorage.setItem("@biro:user", JSON.stringify(user));
    api.defaults.headers.Authorization = access_token;
    yield put(signInSucess(access_token, user));
  }
}

export function* setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;
  if (token) {
    yield (api.defaults.headers.Authorization = token);
  }
}

function signOut() {
  history.push("/");
}

export default all([
  takeLatest("persist/REHYDRATE", setToken),
  takeLatest("@auth/SIGN_IN_REQUEST", signIn),
  takeLatest("@auth/SIGN_OUT", signOut),
  takeLatest("@auth/SIGN_UP_REQUEST_WITH_TOKEN_REDIRECT", signIn),
]);
