export enum DocumentStatus {
  reproved = "reproved",
  analyze = "analyze",
  aproved = "aproved",
}

export const documentStatusTranslated = {
  [DocumentStatus.reproved]: "Reprovado",
  [DocumentStatus.analyze]: "Em análise",
  [DocumentStatus.aproved]: "Aprovado",
};

export interface Document {
  extension: string[];
  field_name: string;
  option: string;
  title: string;
  document_necessary_id: number;
  document_histories: DocumentHistory[];
  name: string;
  archive: Archive | null;
  document: {
    id: number;
    document_necessary_id: number;
    cause_id: number;
    status: string;
    created_at: string;
    updated_at: string;
    checked: boolean | null;
    observation: boolean | null;
    month: any;
    year: any;
    content_type: string;
  } | null;
}

export interface DocumentSummary {
  status?: string;
  sent?: number;
  approved?: number;
  required?: number;
  optional_checked?: boolean;
}

export interface DocumentHistory {
  history: {
    id: number;
    document_id: number;
    user_id: number;
    status: string;
    created_at: string;
    updated_at: string;
    justification: string | null;
    reverse_status: string | null;
  };
}

export interface Archive {
  justification: string;
  id: number;
  url: string;
  updated_at: string;
  document_necessary_id: number;
  status: DocumentStatus;
  content_type: string;
  cause_id: number;
  src: string;
}

export interface Section {
  title: string;
  description: string;
  kind: string;
  items: Document[];
  info: DocumentInfo;
}

export interface DocumentInfo {
  text: string;
  image: string;
}

export interface DocumentItem {}

export interface Sections {
  sections: Section[];
}
