import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { IconBaseProps } from "react-icons";
import { FiAlertCircle, FiEye, FiEyeOff } from "react-icons/fi";
import { useField } from "@unform/core";

import { Container, Error } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  type?: string;
  label?: string;
  labelColor?: string;
  background?: string;
  color?: string;
  width?: string | number;
  height?: string | number;
  border?: string;
  placeholderColor?: string;
  radius?: string;
  disabled?: boolean;
}

const Input: React.FC<InputProps> = ({
  name,
  background = "",
  color = "",
  height = "",
  width = "",
  labelColor = "",
  border = "",
  radius = "",
  placeholderColor = "",
  icon: Icon,
  label,
  disabled = false,
  type,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(type === "password");

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <>
      {label && <label style={{ color: labelColor }}>{label}</label>}
      <Container
        background={background}
        isErrored={!!error}
        isFocused={isFocused}
        isFilled={isFilled}
        color={color}
        width={width}
        height={height}
        border={border}
        radius={radius}
        placeholderColor={placeholderColor}
        disabled={disabled}
      >
        <input
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          type={passwordVisible ? "password" : "text"}
          disabled={disabled}
          {...rest}
        />

        {type === "password" ? (
          passwordVisible ? (
            <FiEye
              size={20}
              onClick={() => setPasswordVisible(!passwordVisible)}
            />
          ) : (
            <FiEyeOff
              size={20}
              onClick={() => setPasswordVisible(!passwordVisible)}
            />
          )
        ) : null}

        {Icon && <Icon size={20} />}
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Container>
    </>
  );
};

export default Input;
