import React, { useState } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import { BreadCrumbNavItem } from "../../components/BreadCrumb/types";
import Topbar from "../../components/Topbar";
import { Issue } from "../../types/issue";
import MessageChat from "./components/MessageChat";
import Sidebar from "./components/Sidebar";
import IssueCard from "./components/Sidebar/Issue";
import { IssuesMock } from "./components/Sidebar/issueMock";

import {
  CenterContent,
  Container,
  Content,
  // IssueCard,
  MessagesBox,
} from "./styles";

const MessagesDiscussion: React.FC = () => {
  const [issueSelected, setIssueSelected] = useState<Issue>();

  const breadCrumbNavItems: BreadCrumbNavItem[] = [
    { label: "Causa", url: `/causes` },
    { label: "Notas" },
  ];

  if (!issueSelected) {
    return (
      <Container>
        <Topbar />
        <BreadCrumb navItems={breadCrumbNavItems} />
        <CenterContent>
          <MessagesBox>
            {IssuesMock.map((issue) => (
              <IssueCard
                issue={issue}
                key={issue.id}
                onClick={() => setIssueSelected(issue)}
              />
            ))}
          </MessagesBox>
        </CenterContent>
      </Container>
    );
  }

  return (
    <Container>
      <Topbar />
      <BreadCrumb navItems={breadCrumbNavItems} />
      <Content>
        <MessageChat issue={issueSelected} />
        <Sidebar
          changeSelectedIssue={setIssueSelected}
          issueSelected={issueSelected}
        />
      </Content>
    </Container>
  );
};

export default MessagesDiscussion;
