export function updateProfileRequest(data) {
  return {
    type: '@user/UPDATE_PROFILE_REQUEST',
    payload: { data }
  };
}

export function updateProfile(profile) {
  return {
    type: '@user/UPDATE_PROFILE',
    payload: { profile }
  };
}

export function updateProfileFailure() {
  return {
    type: '@user/UPDATE_PROFILE_FAILURE'
  };
}

export function updateEmailProfile(payload) {
  return {
    type: '@user/UPDATE_EMAIL_PROFILE',
    payload
  };
}
