import { useEffect, useState } from "react";
import { CausesService } from "../../services/Causes/CausesService";
import { Section } from "../../types/document";
import DocumentUpload from "./DocumentUpload";
import { useQueryParams } from "../../hooks/useQuery";

import { FiCheck, FiChevronDown, FiChevronUp, FiInfo } from "react-icons/fi";
import { useQuery } from "react-query";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
//styles
import {
  Card,
  Column,
  Container,
  DocumentStatusBox,
  DocumentStatusText,
  DocumentTitle,
  LoadingBox,
  Row,
  SaibaMais,
} from "./styles";
import InfoDialog from "../InfoDialog";

const DocumentCard = ({ cause }: any) => {
  const [isOpen, setIsOpen] = useState<number>(-1);
  const [infoIsOpen, setInfoIsOpen] = useState<boolean>(false);
  const [notificatedDoc, setNotificatedDoc] = useState<Section>();
  const [info, setInfo] = useState<any>();
  const params = useQueryParams();
  const notificatedDocId = params.get("notificated-doc");

  const { data, isLoading } = useQuery(
    ["CausesServiceGetList", cause],
    () => CausesService.getDocumentsOfCause(cause),
    {
      onError: () => toast.error("Não foi possível carregar os documentos"),
    }
  );

  useEffect(() => {
    if (notificatedDocId) {
      const doc: any = data?.sections.find((section, index) =>
        section.items.find(
          (doc) => doc.archive?.id?.toString() === notificatedDocId
        )
      );
      setNotificatedDoc(doc);
    }
  }, [data?.sections, notificatedDocId]);

  if (isLoading) {
    return (
      <LoadingBox>
        Carregando lista de documentos...
        <ReactLoading
          type="bubbles"
          color="var(--primary)"
          height="120px"
          width="120px"
        />
      </LoadingBox>
    );
  }

  function handleSessionStatus(sessions: Section) {
    const sessionsWithArchive = sessions?.items?.map((item) => {
      if (item.archive) return item;
      return null;
    });

    if (sessionsWithArchive) {
      const hasReproved = sessionsWithArchive.find(
        (sessionsWithArchive) =>
          sessionsWithArchive?.archive?.status === "reproved"
      );

      const allArchiveApproved = sessionsWithArchive.filter(
        (session) => session?.archive?.status === "aproved"
      );

      const allArchiveAnalize = sessionsWithArchive.filter(
        (session) => session?.archive?.status === "analyze"
      );

      if (allArchiveApproved.length === sessionsWithArchive.length)
        return (
          <>
            <DocumentStatusText color={"#54AD1D"}>
              Todos arquivos aprovados
            </DocumentStatusText>
            <FiCheck
              color="#54ad1d"
              size={20}
              style={{ background: "#E0F0D6" }}
            />
          </>
        );

      if (
        allArchiveAnalize.length === sessionsWithArchive.length ||
        allArchiveApproved.length + allArchiveAnalize.length ===
          sessionsWithArchive.length
      )
        return (
          <DocumentStatusText color={"#1D7AAD"}>
            Enviado. Aguardando aprovação
          </DocumentStatusText>
        );

      if (hasReproved)
        return (
          <DocumentStatusText color={"#A6042B"}>
            Há arquivos que foram recusados
          </DocumentStatusText>
        );
    }

    return (
      <DocumentStatusText color={"#E27A31"}>
        Há arquivos pendente
      </DocumentStatusText>
    );
  }

  const handleOpenInfo = (index: number) => {
    if (notificatedDoc) {
      setIsOpen(index);
      setNotificatedDoc(undefined);
      if (index === isOpen) return setIsOpen(-1);
    } else {
      if (index === isOpen) return setIsOpen(-1);
      setIsOpen(index);
    }
  };

  return (
    <Container>
      <InfoDialog isOpen={infoIsOpen} setIsClosed={setInfoIsOpen} info={info} />
      {data?.sections.map((item, index) => (
        <Card
          key={index}
          isOpen={isOpen === index || notificatedDoc?.title === item.title}
          highlighted={notificatedDoc?.title === item.title}
        >
          {isOpen === index || notificatedDoc?.title === item.title ? (
            <>
              <header>
                <Row>
                  <Column>
                    <DocumentTitle isOpen={isOpen === index}>
                      {item.title}{" "}
                    </DocumentTitle>
                    <p>{item.description}</p>
                  </Column>
                  {item.info.image || item.info.text ? (
                    <SaibaMais
                      onClick={() => {
                        setInfo(item.info);
                        setInfoIsOpen(!infoIsOpen);
                      }}
                    >
                      <FiInfo size={20} />
                      <span>Saiba mais</span>
                    </SaibaMais>
                  ) : null}
                </Row>
                <FiChevronUp
                  color="#7C878D"
                  size={24}
                  onClick={() => handleOpenInfo(index)}
                />
              </header>
              {item.items.map((document, index) => (
                <DocumentUpload
                  key={index}
                  causeId={cause}
                  document={document}
                  necessaryId={document?.document_necessary_id!}
                  archiveData={document?.archive!}
                  highlighted={
                    notificatedDocId === document?.archive?.id?.toString()
                  }
                />
              ))}
            </>
          ) : (
            <>
              <header>
                <DocumentTitle isOpen={isOpen === index}>
                  {item.title}
                  {item.info.image || item.info.text ? (
                    <SaibaMais
                      onClick={() => {
                        setInfo(item.info);
                        setInfoIsOpen(!infoIsOpen);
                      }}
                    >
                      <FiInfo size={20} />
                      <span>Saiba mais</span>
                    </SaibaMais>
                  ) : null}
                </DocumentTitle>
                <DocumentStatusBox>
                  {handleSessionStatus(item)}
                  <FiChevronDown
                    color="#7C878D"
                    size={24}
                    onClick={() => handleOpenInfo(index)}
                  />
                </DocumentStatusBox>
              </header>
            </>
          )}
        </Card>
      ))}
    </Container>
  );
};

export default DocumentCard;
