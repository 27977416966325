import { useState, useMemo } from "react";
import { MdNotifications } from "react-icons/md";

import { Container, Badge } from "./styles";
import { NotificationsService } from "../../services/Notifications/NotificationsService";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

export default function Notifications() {
  const [visible, setVisible] = useState(false);

  const { data } = useQuery(
    "GetNotificationsList",
    NotificationsService.getNotifications
  );

  const hasUnread = useMemo(
    () => !!data?.find((notification) => notification.read === false),
    [data]
  );

  // useEffect(() => {
  //   async function loadNotification() {
  //     const response = await api.get("notifications");

  //     const data = response.data.map((notification) => ({
  //       ...notification,
  //       timeDistance: formatDistance(
  //         parseISO(notification.createdAt),
  //         new Date(),
  //         {
  //           addSufix: true,
  //           locale: pt,
  //         }
  //       ),
  //     }));

  //     setNotifications(data);
  //   }

  //   loadNotification();
  // }, []);

  function handleToggleVisible() {
    setVisible(!visible);
  }

  return (
    <Container>
      <Link to="/notifications">
        <Badge onClick={handleToggleVisible} hasUnread={hasUnread}>
          <MdNotifications color="var(--secondary)" size={24} />
        </Badge>
      </Link>

      {/* <NotificationList visible={visible}>
        <Scroll>
          {notifications.map((notification) => (
            <Notification key={notification.id} unRead={!notification.read}>
              <SpacedRow>
                <p>{notification.content}</p>
                <time>{notification.timeDistance}</time>
              </SpacedRow>
              {!notification.read && (
                <button
                  type="button"
                  onClick={() => handleMarkAsRead(notification.id)}
                >
                  Marcar como Lida
                </button>
              )}
            </Notification>
          ))}
        </Scroll>
        <footer>
          <a href="/notifications">
            <span>ver todas</span>
          </a>
        </footer>
      </NotificationList> */}
    </Container>
  );
}
