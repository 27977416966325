import { GetNotificationsResponse } from "../../types/notification";
import api from "../api";

export const getNotifications = async (): Promise<GetNotificationsResponse> => {
  const { data } = await api.get<any>(`client/notifications`);
  return data;
};

export const setNotificationRead = async (id: number): Promise<void> => {
  const { data } = await api.put<any>(`client/notification/${id}/read`);
  return data;
};

export const NotificationsApi = {
  getNotifications,
  setNotificationRead,
};
