import styled from "styled-components";

interface MessageProps {
  isMine: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
  padding: 0 1rem;
  overflow-y: auto;
  max-height: calc(100vh - 10.1rem);
  height: 100%;
  width: auto;

  > span {
    font-weight: bold;
    padding: 0.5rem 0;
    text-align: center;
  }
`;

export const Message = styled.div<MessageProps>`
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: ${({ isMine }) => (isMine ? "flex-end" : "flex-start")};

  > span {
    padding: 1rem;
    border-radius: 1rem;
    background: ${({ isMine }) =>
      isMine ? "var(--primary)" : "var(--gray-200)"};
    color: ${({ isMine }) => (isMine ? "var(--white)" : "var(--gray-600)")};
  }
`;
