import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 800px;
  > header {
    margin-top: 15px;
    display: flex;
    width: 80vw;

    h1 {
      font-family: "Roboto";
      font-weight: 700;
      font-size: 2rem;
      align-items: center;
    }
  }
`;

export const Content = styled.div`
  width: 80vw;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  > header {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80vw;
    min-width: 800px;
    > aside {
      display: flex;
      flex-direction: row;
      height: 3rem;
    }

    > div {
      font-weight: bold;
      font-size: 1.5rem;
      color: var(--title);
    }
  }
  label {
    font-size: 18px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-top: 5px;
    color: var(--title);
  }

  .p-accordion {
    margin-top: 1rem;
    min-width: 800px;
    width: 100%;
  }

  .p-accordion-content {
    background: #fff;
    padding: 0rem 1rem;
  }

  .p-accordion-header {
    font-size: 18px;
    font-family: "Roboto";
    font-style: normal;
    padding: 1rem 0.5rem;
    border-radius: 4px;
    background: #fff;

    > a {
      > .pi {
        color: var(--title);
      }
      .p-accordion-header-text {
        display: flex;
        color: var(--title);
        .pi {
          color: var(--primary);
          margin: 0 0.5rem;
        }
      }
    }
  }
`;

export const CauseTitle = styled.div`
  > p {
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 700;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #374954;
  }
`;

export const CauseMediaList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  overflow-y: auto;
`;

export const Media = styled.iframe`
  display: flex;
  height: 25rem;
  min-width: 50rem;
  border-radius: 8px;
  margin-bottom: 1rem;
`;

export const AccompanimentButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  flex-direction: column;
  padding: 1rem;
  margin-left: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  color: #fff;
  font-weight: bold;
  transition: filter 0.2s;

  > svg {
    margin-bottom: 2px;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;
export const DocumentationButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  flex-direction: column;
  border: none;
  padding: 1rem;
  margin-left: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  color: #fff;
  font-weight: bold;
  transition: filter 0.2s;

  > svg {
    margin-bottom: 2px;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;
