import styled, { css } from "styled-components";
import Button from "../../../../../components/Button";

export const Container = styled.div`
  padding: 1rem;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  > header {
    display: flex;
    flex-direction: "row";
    align-items: center;
    margin: 0.3rem 0;

    &:nth-child(2) {
      margin-left: 1rem;
    }
  }
`;

const dragActive = css`
  border-color: #07bc0c;
  color: #07bc0c;
`;

const dragReject = css`
  border-color: var(--alert);
  color: var(--alert);
`;

interface UploadFilesZoneProps {
  isDragActive: boolean;
  isDragReject: boolean;
}

export const UploadFilesZone = styled.div<UploadFilesZoneProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  border-radius: 12px;
  border: 1px dashed black;
  background: #fff;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  height: 100%;

  ${({ isDragActive }) => isDragActive && dragActive}
  ${({ isDragReject }) => isDragReject && dragReject}


  > p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

interface PreviewProps {
  src: string;
}

export const Preview = styled.div<PreviewProps>`
  width: 36px;
  height: 36px;
  border-radius: 5px;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin: 0 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    display: none;
    color: var(--gray-200);
  }

  &:hover {
    cursor: pointer;
    svg {
      display: flex;
    }
  }

  ${({ src }) =>
    src === "" &&
    css`
      background: var(--gray-300);
      color: var(--gray-800);
      font-size: 1.3rem;
      font-weight: bold;
    `}
`;

export const Dropzone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const GoBackButton = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  width: fit-content;
  /* margin: 0 1rem; */

  &:hover {
    cursor: pointer;
    filter: brightness(0.8);
    background: var(--gray-200);
  }
  span {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--secondary);
    margin: 0;
  }
  svg {
    color: var(--secondary);
  }
`;

export const ButtonsBox = styled.div`
  min-width: fit-content;
  display: flex;
`;

export const TextareaBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background: #fff;
  border-radius: 12px;
  border: 1px solid black;
  width: 100%;
  height: 100%;

  textarea {
    border: 0;
    padding: 1rem;
    width: 100%;
    resize: none;
    border-radius: 12px;
  }
`;

export const SendButton = styled(Button)`
  height: 100%;
  margin: 0;
  width: auto;
  transition: filter 0.2s;
  border-radius: 0;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;

  &:hover {
    filter: brightness(0.8);
  }
`;
export const UploadImage = styled(Button)`
  height: 100%;
  margin: 0;
  transition: filter 0.2s;
  border-radius: 0;
  background: var(--turquey);
  &:hover {
    filter: brightness(0.8);
  }
`;
