import styled from "styled-components";
import { darken } from "polished";

export const Span = styled.span`
  padding: 0.125rem 1.125rem;
  background: ${({ color }) => color};
  color: ${({ color }) => (color ? darken(0.5, `${color}`) : color)};
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 0.85rem;
  line-height: 140%;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 1080px) {
    font-size: 0.875rem;
    padding: 0.125rem 0.625rem;
  }

  @media (max-width: 820px) {
    font-size: .75rem;
    padding: 0.125rem 0.5rem;
  }
`;
