import React from "react";
import { Issue } from "../../../../../types/issue";

import { Container } from "./styles";

interface IssueDetailedProps {
  issue: Issue;
}

const IssueDetailed = ({ issue }: IssueDetailedProps) => {
  return (
    <Container>
      <p>{issue.description} </p>
    </Container>
  );
};

export default IssueDetailed;
