import styled, { css } from "styled-components";

interface ContainerProps {
  selected?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  justify-content: space-between;
  max-height: calc(100vh - 7.1rem);

  ${({ selected }) =>
    selected
      ? null
      : css`
          align-items: center;
          justify-content: center;

          > h1 {
            display: flex;
            align-items: center;

            svg {
              margin-left: 1rem;
            }
          }
        `}
`;

export const Content = styled.main`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
  padding: 0 1rem;
  overflow-y: auto;
  max-height: calc(100vh - 10.1rem);
  height: 100%;
  width: 100%;
`;

export const ChatFooter = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 12rem;
`;
