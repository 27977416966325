import { AiOutlineArrowRight } from "react-icons/ai";
import { Issue } from "../../../../types/issue";
import ChatInputBox from "./ChatInputBox";
import ChatConversation from "./ChatConversation";

import { Container, Content, ChatFooter } from "./styles";

interface MessageChatProps {
  issue?: Issue;
}

const MessageChat = ({ issue }: MessageChatProps) => {
  if (!issue) {
    return (
      <Container selected={!!issue}>
        <h1>
          Selecione uma Mensagem ao lado <AiOutlineArrowRight />{" "}
        </h1>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <ChatConversation chatTitle={issue?.title} messages={issue?.messages} />
      </Content>
      <ChatFooter>
        <ChatInputBox />
      </ChatFooter>
    </Container>
  );
};

export default MessageChat;
