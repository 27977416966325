import React, { useRef } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

import Topbar from "../../components/Topbar";
import history from "../../services/history";
import { ProfileService } from "../../services/Profile/ProfileService";

import { Button, Container, Content, Input } from "./styles";
import { StoreState } from "../../types/store";
import { useSelector } from "react-redux";

const CpfCheck: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { name } = useSelector((state: StoreState) => state.user.profile);

  const { mutate, isLoading } = useMutation(
    "CpfCheck",
    () => ProfileService.CheckCpf(formRef.current?.getData().cpf),
    {
      onSuccess: (data) => {
        history.push("/causes");
      },
      onError: (err: any) => {
        toast.error(err.response.data.processes_not_found[0]);
        history.push("/documents/0");
      },
    }
  );

  return (
    <>
      <Topbar showNotificationsBadge={false} />
      <Container>
        <span>
          Olá <strong>{name}</strong>,<br /> para que possamos buscar seus
          processos no escritorio <br />
          vamos precisar que nos informe seu CPF.
        </span>
        <Form ref={formRef} onSubmit={() => mutate()}>
          <label>Insira seu Cpf</label>
          <Content>
            <Input
              height="3rem"
              width="100%"
              type="text"
              placeholder="Digite seu Cpf"
              name="cpf"
            />
            <Button loading={isLoading} type="submit">
              Enviar
            </Button>
          </Content>
        </Form>
      </Container>
    </>
  );
};

export default CpfCheck;
