export type Client = {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  status: ClientStatus;
  phone: string;
  client_identity: object;
  email: string;
  reset_password: string;
  token_firebase: string;
};

export enum ClientStatus {
  pending = "pending",
  pending_generation = "pending_generation",
  pending_subscription = "pending_subscription",
  analyze = "analyze",
  confirmed = "confirmed",
  reproved = "reproved",
}
