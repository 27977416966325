import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useCallback, useRef } from "react";
import { FiLock } from "react-icons/fi";
import { useMutation } from "react-query";
import Topbar from "../../components/Topbar";
import { useQueryParams } from "../../hooks/useQuery";
import { AuthService } from "../../services/Auth/AuthService";
import history from "../../services/history";
import { ResetPasswordService } from "../../types/auth";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  Content,
  ContinueButton,
  RecoverPasswordBox,
  Container,
  LoginInput,
} from "./styles";
import getValidationErrors from "../../utils/getValidationErrors";

const ResetPassword = () => {
  const query = useQueryParams();
  const token = query.get("token");
  const formRef = useRef<FormHandles>(null);

  const ResetPasswordMutation = useMutation(
    "ResetPasswordMutation",
    AuthService.ResetPassword,
    {
      onSuccess: (data: any) => {
        toast.success(data?.message?.client_updated_password);
        history.push("/");
      },
      onError: (data: any) => {
        toast.error(data?.response?.data?.client_updated_password);
      },
    }
  );

  const handleSubmit = useCallback(
    async (data: ResetPasswordService) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().required("Senha obrigatória"),
          passwordConfirmation: Yup.string()
            .oneOf(
              [Yup.ref("password"), null],
              "a senha não condiz com o campo anterior"
            )
            .required("Senha obrigatória"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (token) {
          ResetPasswordMutation.mutate({
            password: data.password,
            password_confirmation: data.password_confirmation,
            token,
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [ResetPasswordMutation, token]
  );

  return (
    <>
      <Topbar />
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Content>
            <h1>Redefina sua senha</h1>
            <span>
              Basta digitar sua nova senha abaixo e repetir a senha. Ah, é
              importante que sua senha tenha no mínimo 8 caracteres.
            </span>
            <main>
              <LoginInput
                name="password"
                icon={FiLock}
                type="password"
                placeholder="Senha"
              />
              <LoginInput
                name="passwordConfirmation"
                icon={FiLock}
                type="password"
                placeholder="Insira sua senha novamente"
              />
            </main>
            <RecoverPasswordBox></RecoverPasswordBox>
            <ContinueButton
              loading={ResetPasswordMutation.isLoading}
              type="submit"
            >
              CONTINUAR
            </ContinueButton>
          </Content>
        </Form>
      </Container>
    </>
  );
};

export default ResetPassword;
