import { format } from "date-fns";
import ptbr from "date-fns/locale/pt-BR";
import { FiCornerDownRight } from "react-icons/fi";
import { IssueComponent, IssueTypescolors } from "../../../../../types/issue";

import {
  Container,
  Description,
  LastReplyDate,
  ReadIssueText,
  Title,
  Type,
} from "./styles";

const Issue = ({ issue, onClick }: IssueComponent) => {
  const { lastReplyDate, id, hasUnReadMessage, title, description, type } =
    issue;

  const DateFormatted = format(
    lastReplyDate,
    "EEEE ' • ' dd ' de 'MMMM' de 'kk:mm'h'",
    {
      locale: ptbr,
    }
  );

  return (
    <Container onClick={onClick}>
      {hasUnReadMessage ? (
        <header>
          <div>
            <span>#{id}</span>
            <FiCornerDownRight />
          </div>
          <div>
            <Type color={IssueTypescolors[type]}>{type} </Type>
            <ReadIssueText read={hasUnReadMessage}>Nova Mensagem</ReadIssueText>
          </div>
        </header>
      ) : (
        <header>
          <span>#{id}</span>
          <Type color={IssueTypescolors[type]}>{type} </Type>
        </header>
      )}
      <Title>{title} </Title>
      <Description>{description} </Description>
      <LastReplyDate>{DateFormatted}</LastReplyDate>
    </Container>
  );
};

export default Issue;
