import styled, { css } from "styled-components";

import Tooltip from "../Tooltip";

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  background: string;
  color: string;
  border: string;
  width: string | number;
  height: string | number;
  placeholderColor: string | number;
  radius: string;
  disabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  border-radius: 4px;
  display: flex;
  align-items: center;
  border: 1px solid #aab6bc;

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.radius &&
    css`
      border-radius: ${({ radius }: any) => radius}; ;
    `}

  ${(props) =>
    props.width &&
    css`
      width: ${({ width }: any) => width};
    `}

    ${(props) =>
    props.height &&
    css`
      height: ${({ height }: any) => height};
    `}

    ${(props) =>
    props.border &&
    css`
      border: ${({ border }: any) => border};
    `}

    ${(props) =>
    props.background &&
    css`
      background: ${({ background }: any) => background};
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: var(--secondary);
      border-color: var(--secondary);
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: var(--secondary);
    `}

    ${(props) =>
    props.disabled &&
    css`
      background: ${({ disabled, background }: any) =>
        disabled ? "#d6d6d6" : background};
      cursor: not-allowed;
    `}


  input {
    background: transparent;
    flex: 1;
    border: 0;
    &::placeholder {
      color: #666360;
    }
    ${(props) =>
      props.disabled &&
      css`
        background: ${({ disabled, background }: any) =>
          disabled ? "#d6d6d6" : background};
        cursor: not-allowed;
      `}
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin: 0 16px;
  svg {
    margin: 0;
  }
  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
