import styled from "styled-components";
import Button from "../../components/Button";
import Input from "../../components/Input";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 4rem);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: 21.875rem;
  padding: 2rem 1rem;

  > h1 {
    font-family: Alegreya;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #7c878d;
  }

  > main {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 2rem;
  }
`;

export const LoginInput = styled(Input)`
  align-items: flex-start;
  padding: 0.75rem 1rem;
  background: #ffffff;
  width: 100%;
  border: 1px solid #aab6bc;
  box-sizing: border-box;
  border-radius: 4.8px;
`;

export const RecoverPasswordBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.1rem;

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #30c5ff;
    cursor: pointer;

    &:hover {
      filter: brightness(0.5);
    }
  }
`;

export const ContinueButton = styled(Button)`
  margin-top: 2rem;
  background: var(--primary);
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: none;
  width: 100%;
  height: 3rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #ffffff;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const LoginTypeText = styled.span`
  margin-top: 1.5rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #30c5ff;
  cursor: pointer;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.5);
  }
`;
