import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FiArrowLeft, FiImage, FiSend, FiTrash2, FiX } from "react-icons/fi";
import { ImageFile } from "../../../../../types/file";
import {
  ButtonsBox,
  Container,
  GoBackButton,
  Preview,
  SendButton,
  TextareaBox,
  UploadFilesZone,
  UploadImage,
} from "./styles";

const ChatInputBox: React.FC = () => {
  const [textOrImageBox, setTextOrImageBox] = useState(false);
  const [files, setFiles] = useState<ImageFile[]>([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file: File) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles([...files, ...newFiles]);

      setTextOrImageBox(false);
    },
    [files]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
  });

  const handleRemoveImage = (index: number) => {
    setFiles(files.filter((_, fileIndex) => fileIndex !== index));
  };

  const renderDropzoneUploadMessage = (
    isDragAccept: boolean,
    isDragReject: boolean
  ) => {
    if (isDragAccept) {
      return <p>"Solte o arquivo aqui"</p>;
    }
    if (isDragReject) {
      return (
        <p>
          "Tipo de arquivo não suportado"
          <FiX color="var(--alert)" size={24} />
        </p>
      );
    }
    return <p>"Arraste um arquivo ou clique para selecionar"</p>;
  };

  return (
    <Container>
      {textOrImageBox ? (
        <>
          <GoBackButton onClick={() => setTextOrImageBox(false)}>
            <FiArrowLeft size={24} />
            <span>Voltar</span>
          </GoBackButton>
          <UploadFilesZone
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input accept="image/*" {...getInputProps()} />
            {renderDropzoneUploadMessage(isDragAccept, isDragReject)}
          </UploadFilesZone>
        </>
      ) : (
        <>
          <header>
            <span>Digite sua Mensagem</span>
            {files?.length !== 0 &&
              files?.map((file, index) => {
                return index < 4 ? (
                  <Preview key={index} src={file?.preview}>
                    <FiTrash2
                      size={24}
                      onClick={() => handleRemoveImage(index)}
                    />
                  </Preview>
                ) : null;
              })}
            {files?.length > 4 && <Preview src="">{files?.length}</Preview>}
          </header>
          <TextareaBox>
            <textarea />
            <ButtonsBox>
              <UploadImage
                type="button"
                onClick={() => setTextOrImageBox(true)}
              >
                <FiImage color="#fff" size={20} />
              </UploadImage>
              <SendButton>
                <FiSend color="#fff" size={20} />
              </SendButton>
            </ButtonsBox>
          </TextareaBox>
        </>
      )}
    </Container>
  );
};

export default ChatInputBox;
