// import { useQuery } from "react-query";
// import { CausesService } from "../../../services/Causes/CausesService";

import { Column, Container, Row } from "./styles";

interface INecessaryDocuments {
  cause: Number | undefined | string;
}

const NecessaryDocuments = ({ cause }: INecessaryDocuments) => {
  // const { data, error, isLoading } = useQuery(
  //   ["CausesServiceGetList", cause],
  //   () => CausesService.getDocumentsOfCause(cause)
  // );

  return (
    <Container>
      <Column>
        <label>Documentos necessários</label>
        {/* <span>Formatos aceitos: JPG, PNG e PDF</span> */}
      </Column>

      <Row>
        {/* <RoundedText backgroundColor="#EAEDEF" color="#374954">
          Total: 22
        </RoundedText>
        <RoundedText backgroundColor="#1D7AAD">22 Enviados</RoundedText>
        <RoundedText backgroundColor="#54AD1D">10 Aprovados</RoundedText>
        <RoundedText backgroundColor="#E27A31">1 Pendente</RoundedText>
        <RoundedText backgroundColor="#A6042B">1 Recusado</RoundedText> */}
      </Row>
    </Container>
  );
};

export default NecessaryDocuments;
