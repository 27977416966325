import { Demand } from "../demand";
import { Document, DocumentSummary } from "../document";
import { Payment } from "../payment";

export type CauseProps = {
  client: {
    id: Number;
    name: string;
    phone: string;
    email: string;
    token_firebase: string;
    status: string;
    reset_password: boolean;
    created_at: string;
    updated_at: string;
  };
  data: any;
  id: Number;
  stages: any;
  client_id: Number;
  demand_id: Number;
  created_at: string;
  updated_at: string;
  demand: Demand;
  contract: any;
  payment: Payment;
  informations: {
    deadline: string;
    documents_sent: string;
    validated_documents: string;
    in_analysis: string;
    required: string;
    sent: string;
    analyzed: string;
    approved: string;
    reproved: string;
  };
  step: CauseStep;
  status: CauseStatus;
  demand_code: string;
  documents: Document[];
  document_summary: DocumentSummary;
  process_number: string;
};

export type CauseGetList = {
  data: CauseProps[];
};

export type CauseGetService = {
  data: CauseProps[];
};

export enum CauseStatus {
  pending = "pending",
  pending_generation = "pending_generation",
  pending_subscription = "pending_subscription",
  analyze = "analyze",
  concluded = "concluded",
}

export enum CauseStep {
  contract = "contract",
  payment = "payment",
  documentation = "documentation",
  execution = "execution",
}

export type CauseStage = {
  id: Number;
  cause_id: Number;
  title: string;
  created_at: string;
  updated_at: string;
  status: string;
  content: string;
};

export type GetCauseStageResponse = {
  data: CauseStage[];
};
