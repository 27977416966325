import React, { useState } from "react";
import { useQuery } from "react-query";
import ReactLoading from "react-loading";
import ClientDataCard from "./ClientDataCard";
import DocumentsNecessary from "./NecessaryDocuments";
import { CausesService } from "../../services/Causes/CausesService";
import DocumentCard from "../../components/DocumentCard";
import Topbar from "../../components/Topbar";
import { CauseStep, CauseProps } from "../../types/cause";
import {
  statusColors,
  statusTranslated,
  stepColors,
  stepTranslated,
} from "../../utils/formatSpanShadow";
import UpdateMailModal from "../../components/UpdateMailModal";

import {
  Container,
  Content,
  Span,
  StepWarningMessage,
  UserNameTitle,
  UserTitleBox,
} from "./styles";
import BreadCrumb from "../../components/BreadCrumb";
import { BreadCrumbNavItem } from "../../components/BreadCrumb/types";
import { useParams } from "react-router-dom";

interface RouteParams {
  id?: string | undefined;
}

const Documents: React.FC = () => {
  const [cardIsOpen, setCardIsOpen] = useState(false);
  let { id } = useParams<RouteParams>();

  const { data = {} as CauseProps, isLoading } = useQuery(
    "getSessionOfCause",
    () => CausesService.getCause(Number(id))
  );

  const breadCrumbNavItems: BreadCrumbNavItem[] = [
    { label: "Causa", url: `/cause/${id}` },
    { label: "Documentação", url: `/documents/${id}` },
  ];

  if (isLoading) {
    return (
      <Container>
        <Topbar />
        <ReactLoading
          type="bubbles"
          color="var(--primary)"
          height="46px"
          width="46px"
        />
      </Container>
    );
  }

  return (
    <>
      <Topbar />
      <BreadCrumb navItems={breadCrumbNavItems} />
      <Container>
        <Content>
          <UserTitleBox>
            <UserNameTitle>{data?.demand?.title}</UserNameTitle>
            <div>
              <Span color={stepColors[data?.step]}>
                {stepTranslated[data?.step]}
              </Span>
              <Span color={statusColors[data?.status]}>
                {statusTranslated[data?.status]}
              </Span>
            </div>
          </UserTitleBox>
          <ClientDataCard
            isOpen={cardIsOpen}
            onClick={setCardIsOpen}
            payment={data?.payment}
            demand={data?.demand}
            contract={data?.contract}
            createdAt={data?.created_at}
          />
          {data?.step !== CauseStep.documentation ? (
            <StepWarningMessage>
              Olá, você está na etapa <strong>{data?.step}</strong>, o site é
              para envio de documentos, para poder enviar documentos pelo site,
              acesse o aplicativo e continue até a etapa{" "}
              <strong>"Documentação"</strong>, logo depois pode voltar aqui para
              enviar seus documentos
            </StepWarningMessage>
          ) : (
            <>
              <DocumentsNecessary cause={id} />
              <DocumentCard cause={id} />
            </>
          )}
        </Content>
        <UpdateMailModal />
      </Container>
    </>
  );
};

export default Documents;
