import React, { useCallback, useRef } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { FiLock, FiMail } from "react-icons/fi";
import ReactModal from "react-modal";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Form } from "@unform/web";

import { StoreState } from "../../types/store";
import { userProfile } from "../../types/user";
import { ProfileService } from "../../services/Profile/ProfileService";
import { AuthService } from "../../services/Auth/AuthService";
import { updateProfile } from "../../store/modules/user/actions";
import getValidationErrors from "../../utils/getValidationErrors";

import { Input, ModalSubmitButton, UpdateMailModalContent } from "./styles";

const UpdateMailModal: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const userData = useSelector((state: StoreState) => state.user.profile);
  const dispatch = useDispatch();

  const updateProfileMutation = useMutation(
    "UpdateProfileMutation",
    ProfileService.UpdateMail,
    {
      onSuccess: (data) => {
        dispatch(updateProfile(data?.data));
        toast.success("E-mail atualizado com sucesso");
      },
      onError: (error) => {
        toast.error("Não foi possível atualizar seu e-mail");
      },
    }
  );

  const createPasswordMutation = useMutation(
    "CreatePasswordMutation",
    AuthService.CreatePassword,
    {
      onSuccess: (data) => {
        dispatch(updateProfile({ ...userData, reset_password: false }));
        toast.success("Senha atualizada com sucesso");
      },
    }
  );

  const handleUpdateMailSubmit = useCallback(
    async (data: userProfile) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email("Digite um e-mail válido")
            .required("E-mail obrigatório"),
          password: Yup.string()
            .required("Senha obrigatória")
            .min(8, "No mínimo 8 caracteres"),
          passwordConfirmation: Yup.string()
            .oneOf(
              [Yup.ref("password"), null],
              "a senha não condiz com o campo anterior"
            )
            .required("Senha obrigatória"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (userData?.reset_password === true) {
          createPasswordMutation.mutate(data);
        } else {
          updateProfileMutation.mutate({ ...data, reset_password: false });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [createPasswordMutation, updateProfileMutation, userData?.reset_password]
  );

  return (
    <ReactModal
      isOpen={!!userData?.reset_password}
      className="FlexModal"
      overlayClassName="Overlay"
      ariaHideApp={false}
    >
      <UpdateMailModalContent>
        {userData?.reset_password ? (
          <h2>
            Nós, do LUISA MORAES ADVOGADOS, temos muito cuidado com seus dados e
            respeitamos sua privacidade. <br /> <br /> Por favor crie uma nova
            senha, com no mínimo 8 caracteres. <br /> Não se esqueça de
            memorizá-la ou salvar em um lugar seguro.
          </h2>
        ) : (
          <h2>
            Visando facilitar o acesso ao aplicativo e simplificar o uso,
            estamos mudando a forma de entrar no aplicativo, agora você fara
            isso atráves de um email e senha. <br /> <br /> Informa abaixo seu
            melhor email e uma senha com no minimo 8 caracteres
          </h2>
        )}

        <div>
          <Form ref={formRef} onSubmit={handleUpdateMailSubmit}>
            {userData?.reset_password ? (
              <Input
                name="email"
                icon={FiMail}
                type="email"
                placeholder="Email"
                disabled={userData?.reset_password}
                value={userData?.email}
              />
            ) : (
              <Input
                name="email"
                icon={FiMail}
                type="email"
                placeholder="Email"
                disabled={userData?.reset_password}
              />
            )}

            <Input
              name="password"
              icon={FiLock}
              type="password"
              placeholder="Senha"
            />
            <Input
              name="passwordConfirmation"
              icon={FiLock}
              type="password"
              placeholder="Confirme sua Senha"
            />
            <p>
              Depois de salvar/atualizar sua conta você poderá usar seu email e
              senha para entrar no aplicativo
            </p>
            <ModalSubmitButton
              loading={
                userData?.reset_password
                  ? createPasswordMutation.isLoading
                  : updateProfileMutation.isLoading
              }
              type="submit"
            >
              Salvar
            </ModalSubmitButton>
          </Form>
        </div>
      </UpdateMailModalContent>
    </ReactModal>
  );
};

export default UpdateMailModal;
