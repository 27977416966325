import styled, { css } from "styled-components";

interface ContainerProps {
  issueSelected: boolean;
}

export const Container = styled.aside<ContainerProps>`
  width: ${({ issueSelected }) => (issueSelected ? "50%" : "348px")};
  background: var(--gray-200);
  padding: ${({ issueSelected }) => (issueSelected ? "1rem" : "1rem 0")};
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 9.5rem);
  transition: all 0.3s ease-in-out;
  flex-direction: column;

  ${({ issueSelected }) =>
    issueSelected &&
    css`
      > header {
        display: flex;
        flex-direction: "row";
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0.5rem;
        border-radius: 12px;
      }
    `}
`;

export const GoBackButton = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    filter: brightness(0.8);
    background: var(--gray-200);
  }
  span {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--secondary);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--gray-300);
  text-align: center;
  display: block;
`;

export const SearchInputBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0.5rem;

  > input {
    background: none;
    border: none;
    padding: 0 0.5rem;
    width: 100%;
  }

  > svg {
    color: var(--primary);
  }
`;
