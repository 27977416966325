import { profileUpdate } from "../../types/user";
import api from "../api";

export const UpdateMail = async ({
  email,
  password,
  reset_password,
}: profileUpdate) => {
  const { data } = await api.patch(`client`, {
    email,
    password,
    password_confirmation: password,
    reset_password,
  });

  return data;
};

export const CheckCpf = async (cpf: string) => {
  const { data } = await api.get(`client/find_processes?cpf=${cpf}`);
  return data;
};

export const getClient = async () => {
  const { data } = await api.get("client");
  return data;
};

export const ProfileApi = { UpdateMail, CheckCpf, getClient };
