import { format, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

export const parseDate = (date: any) => {
  if (date) {
    const formattedDate = format(
      new Date(parseISO(date)),
      "hh:mm - dd 'de' MMMM, yyyy",
      {
        locale: ptBR,
      }
    );
    return formattedDate;
  }
  return null;
};
