import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 800px;
  > header {
    margin-top: 15px;
    display: flex;
    width: 80vw;

    h1 {
      font-family: "Roboto";
      font-weight: 700;
      font-size: 2rem;
      align-items: center;
    }
  }
`;

export const Content = styled.div`
  width: 80vw;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  > h2 {
    margin: 1rem 0;
    color: var(--title);
  }
`;

export const Cause = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 80vw;
`;

export const CauseDataBox = styled(Link)`
  background: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  transition: filter 0.2s ease-in-out;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const CauseDetailsBox = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  div {
    min-width: 12rem;
    > label {
      font-size: 1rem;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      margin-top: 5px;
      color: var(--title);
      white-space: nowrap;
      cursor: pointer;
    }
    > p {
      font-size: 0.8rem;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      display: flex;
      align-items: center;
      color: #374954;
    }
  }

  aside {
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    > span {
      margin: 0 0.2rem;
    }
  }
`;

export const CausesBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  grid-gap: 1rem;

  margin-bottom: 1.5rem;
`;

export const CauseNumberBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 0 1rem;
  font-weight: bold;
  font-size: 1rem;
  color: black;
  border-right: 1px solid rgb(101, 15, 64, 0.5);
  white-space: nowrap;
  > label {
    font-size: 1rem;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-top: 5px;
    color: var(--title);
    cursor: pointer;
    white-space: nowrap;
    @media (max-width: 1300px) {
      font-size: 0.9rem;
    }
  }
  > p {
    font-size: 0.8rem;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #374954;

    @media (max-width: 1300px) {
      font-size: 0.7rem;
    }
  }
`;

export const AccompanimentButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  flex-direction: column;
  padding: 1rem;
  margin-left: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  color: #fff;
  font-weight: bold;
  transition: filter 0.2s;

  @media (max-width: 1300px) {
    padding: 0.5rem;
  }

  > svg {
    margin-bottom: 2px;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;

export const DocumentationButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  flex-direction: column;
  border: none;
  padding: 1rem;
  margin-left: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  color: #fff;
  font-weight: bold;
  transition: filter 0.2s;

  @media (max-width: 1300px) {
    padding: 0.5rem;
  }

  > svg {
    margin-bottom: 2px;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;
