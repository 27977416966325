import React, { useCallback, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft, FiUser, FiMail, FiLock } from "react-icons/fi";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";

import getValidationErrors from "../../utils/getValidationErrors";

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  Input,
  Button,
} from "./styles";
import { toast } from "react-toastify";
import Topbar from "../../components/Topbar";
import { useMutation } from "react-query";
import { AuthService } from "../../services/Auth/AuthService";

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();

  const { mutate, isLoading } = useMutation(
    "createUser",
    async (data: SignUpFormData) => AuthService.SignUp(data),
    {
      onSuccess(data) {
        toast.success("Usuário criado com sucesso!");
        history.push("/");
      },
      onError(error: any) {
        toast.error(error.message);
      },
    }
  );

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required("Nome obrigatório"),
          email: Yup.string()
            .email("Digite um e-mail válido")
            .required("E-mail obrigatório"),
          password: Yup.string().min(6, "No mínimo 6 digitos"),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref("password"), null],
            "Senhas precisam ser iguais"
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        mutate(data);
        history.push("/");
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        toast.error("Ocorreu um erro ao fazer cadastro, cheque seus dados");
      }
    },
    [history, mutate]
  );

  return (
    <>
      <Topbar />
      <Container>
        <Content>
          <AnimationContainer>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Faça seu cadastro</h1>

              <Input
                name="name"
                icon={FiUser}
                type="text"
                placeholder="Nome"
                width="300px"
              />
              <Input
                name="email"
                icon={FiMail}
                type="text"
                placeholder="E-mail"
                width="300px"
              />
              <Input
                name="password"
                icon={FiLock}
                type="password"
                placeholder="Senha"
                width="300px"
              />
              <Input
                name="password_confirmation"
                icon={FiLock}
                type="password"
                placeholder="Confirme sua senha"
                width="300px"
              />

              <Button loading={isLoading} type="submit">
                Cadastrar
              </Button>
            </Form>

            <Link to="/">
              <FiArrowLeft />
              Voltar para login
            </Link>
          </AnimationContainer>
        </Content>
        <Background />
      </Container>
    </>
  );
};

export default SignUp;
