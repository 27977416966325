import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useQueryParams } from "../../hooks/useQuery";
import history from "../../services/history";
import { signUpRequestWithTokenRedirect } from "../../store/modules/auth/actions";

const SignUpWithToken: any = () => {
  const query = useQueryParams();
  const token = query.get("token");
  const email = query.get("email");
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      let data = { email, token };
      dispatch(signUpRequestWithTokenRedirect(data));
    } else history.push("/");
  }, [dispatch, email, token]);

  return null;
};

export default SignUpWithToken;
