import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Content = styled.main`
  display: flex;
  flex: 1 1 0%;
`;

export const CenterContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex: 1 1 0%;
  padding: 1rem;
`;

export const MessagesBox = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, 30rem);
  grid-auto-rows: minmax(10rem, auto);
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  width: 80%;
`;

export const IssueCard = styled.div`
  background: green;
`;
