import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { DocumentInfo } from "../../types/document";
import { Container, DescriptionBox, ImageBox, LoadingBox } from "./styles";
import ReactLoading from "react-loading";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import remarkYamlConfig from "remark-yaml-config";
import remarkFrontmatter from "remark-frontmatter";
import remarkParse from "remark-parse";
import remarkCommentConfig from "remark-comment-config";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface InfoDialogProps {
  isOpen: boolean;
  setIsClosed: any;
  info: DocumentInfo;
}

const InfoDialog = ({ isOpen, setIsClosed, info }: InfoDialogProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Dialog
      visible={isOpen}
      onHide={setIsClosed}
      style={{ width: "50vw" }}
      closeOnEscape
      focusOnShow
      breakpoints={{ "960px": "75vw", "640px": "100vw" }}
      closable
    >
      <Container>
        {info?.text ? (
          <DescriptionBox>
            <ReactMarkdown
              children={info?.text}
              className="markdown-body"
              remarkPlugins={[
                remarkGfm,
                remarkBreaks,
                remarkYamlConfig,
                remarkParse,
                remarkFrontmatter,
                remarkCommentConfig,
              ]}
            />
          </DescriptionBox>
        ) : null}

        {info?.image ? (
          isLoaded ? null : (
            <LoadingBox>
              <ReactLoading
                type="spinningBubbles"
                color="var(--primary)"
                height="96px"
                width="96px"
              />
            </LoadingBox>
          )
        ) : null}

        {info?.image ? (
          <ImageBox isOnlyImage={!info.text && !!info.image}>
            <TransformWrapper>
              <TransformComponent contentStyle={{ width: "100%" }}>
                <img
                  src={info?.image}
                  style={isLoaded ? {} : { display: "none" }}
                  alt={info?.text}
                  onLoad={() => setIsLoaded(true)}
                />
              </TransformComponent>
            </TransformWrapper>
          </ImageBox>
        ) : null}
      </Container>
    </Dialog>
  );
};

export default InfoDialog;
