import { useCallback, useEffect, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import {
  Content,
  ContinueButton,
  LoginInput,
  RecoverPasswordBox,
} from "./styles";
import { FiMail } from "react-icons/fi";
import { SignInFormData } from "./types";
import getValidationErrors from "../../../utils/getValidationErrors";
import { useMutation } from "react-query";
import { AuthService } from "../../../services/Auth/AuthService";
import history from "../../../services/history";
import { useDispatch, useSelector } from "react-redux";
import { signInRequest } from "../../../store/modules/auth/actions";
import { toast } from "react-toastify";
import { StoreState } from "../../../types/store";
import { Link } from "react-router-dom";
import { ProfileService } from "../../../services/Profile/ProfileService";
import { CausesService } from "../../../services/Causes/CausesService";

const Email = () => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const [isPasswordForgotted, setIsPasswordForgotted] = useState(false);
  const userProfile = useSelector((state: StoreState) => state.user.profile);

  useEffect(() => {
    if (userProfile?.email) {
      formRef.current?.setData({ email: userProfile?.email });
    }
  }, [userProfile?.email]);

  const SignInMutation = useMutation(
    "EmailSignInMutation",
    AuthService.SignIn,
    {
      onSuccess: (data: any) => {
        setAuthTokenInHeaders(data);
      },
      onError: (data) =>
        callToastAlert("Por favor verifique seu usuário ou senha"),
    }
  );

  const RecoverPasswordMutation = useMutation(
    "RecoverPaswordMutation",
    AuthService.RecoverPassword,
    {
      onSuccess: () => {
        toast.success(
          "Enviamos o procedimento para o seu e-mail cadastrado para que você defina uma nova senha"
        );
        setIsPasswordForgotted(false);
      },
      onError: (error) => console.log(error),
    }
  );

  async function setAuthTokenInHeaders(data: any) {
    dispatch(signInRequest(data));
    const existsCpf = await ProfileService.CheckExistsCpfInClientIdentity();

    if (existsCpf) {
      const causes = await CausesService.getCauses();
      causes ? history.push("/causes") : history.push("/documents");
    } else {
      return history.push("/checkcpf");
    }
  }

  function callToastAlert(msg: string) {
    toast.error(msg);
  }

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email("Digite um e-mail válido")
            .required("E-mail obrigatório"),
          password: Yup.string().required("Senha obrigatória"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        SignInMutation.mutate(data);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [SignInMutation]
  );

  const handleRecoverSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email("Digite um e-mail válido")
            .required("E-mail obrigatório"),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        RecoverPasswordMutation.mutate(data);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [RecoverPasswordMutation]
  );

  if (isPasswordForgotted) {
    return (
      <Form ref={formRef} onSubmit={handleRecoverSubmit}>
        <Content>
          <h1>Insira seu e-mail</h1>
          <main>
            <LoginInput name="email" icon={FiMail} placeholder="E-mail" />
          </main>
          <RecoverPasswordBox>
            <span onClick={() => setIsPasswordForgotted(false)}>
              voltar para login
            </span>
          </RecoverPasswordBox>
          <ContinueButton
            loading={RecoverPasswordMutation.isLoading}
            type="submit"
          >
            CONTINUAR
          </ContinueButton>
          {/* <LoginTypeText onClick={() => setLoginType("cellphone")}>
            Me cadastrei pelo celular
          </LoginTypeText> */}
        </Content>
      </Form>
    );
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Content>
        <h1>Login</h1>
        <main>
          <LoginInput name="email" icon={FiMail} placeholder="E-mail" />
          <LoginInput name="password" type="password" placeholder="Senha" />
        </main>
        <RecoverPasswordBox>
          <span onClick={() => setIsPasswordForgotted(true)}>
            esqueceu a senha
          </span>
          <Link to="/signup">
            <span>não possuo cadastro</span>
          </Link>
        </RecoverPasswordBox>
        <ContinueButton loading={SignInMutation.isLoading} type="submit">
          CONTINUAR
        </ContinueButton>
        {/* <LoginTypeText onClick={() => setLoginType("cellphone")}>
          Me cadastrei pelo celular
        </LoginTypeText> */}
      </Content>
    </Form>
  );
};

export default Email;
