import { Span } from "./styles";

interface ColoredSpanProps {
  color: any;
  children: React.ReactNode;
}

const ColoredSpan = ({ color, children, ...rest }: ColoredSpanProps) => {
  return (
    <Span {...rest} color={color}>
      {children}
    </Span>
  );
};

export default ColoredSpan;
