import styled, { css } from "styled-components";
import AppButton from "../../Button";

interface ContainerProps {
  highlighted?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  transition: all 0.2 ease-in-out;
  border-top: 1px solid #eee;

  ${({ highlighted }) =>
    highlighted
      ? css`

          border-top: 2px solid var(--alert);
          border-bottom: 2px solid var(--alert);
        `
      : null}

  > span {
    margin-left: 1rem;
  }

  > div {
    display: flex;
    align-items: center;
  }

  > div p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: .75rem;
    line-height: 140%;
    color: #374954;
    margin-right: 1rem;
    strong {
      color: black;
    }
  }
`;

export const SendButton = styled(AppButton)`
  background: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 0;
  margin-right: 1rem;
  width: 170px;
  height: 40px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 140%;
  color: #ffffff;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }

  > div {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    align-items: center;
    justify-content: space-between;
    > svg {
      margin: 0 5px;
      &:hover {
        background: none;
      }
    }
  }
`;

interface UploadedFileStatusMessageProps {
  color: string;
}

export const UploadedFileBox = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 1rem;
  }
`;

export const UploadedFileStatusMessage = styled.span<UploadedFileStatusMessageProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: .8rem;
  line-height: 140%;
  color: ${({ color }) => color};
  /* margin-right: 1rem; */

  > button {
    margin-left: .6rem;
  }

  > svg {
    margin-left: 1rem;
  }
`;

interface ViewAndRemoveButtonProps {
  hightlighted?: boolean;
}

export const ViewAndRemoveButton = styled(AppButton)<ViewAndRemoveButtonProps>`
  margin: 0;
  height: 40px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: var(--primary);
  background: #fff;
  transition: filter 0.2s;
  border: 2px solid var(--primary);
  box-sizing: border-box;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const ViewAndRemoveBox = styled.div`
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button {
    margin-left: .6rem;
    > svg {
      margin: 0 4px;
    }
  }
`;

interface ModalContentProps {
  expandContent: boolean;
}

export const ModalContent = styled.div<ModalContentProps>`
  display: flex;
  height: ${({ expandContent }) => (expandContent ? "90%" : "80%")};
  justify-content: center;
  background: #c4c4c4;
  border-radius: 5px;

  img {
    width: auto;
    max-height: 70vh;
    @media (max-width: 1480px) {
      width: 58rem;
    }

    @media (max-width: 1250px) {
      width: 52rem;
    }

    @media (max-width: 1100px) {
      width: 45rem;
    }
  }

  embed {
    width: 100%;
  }
`;

export const ReprovedBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const JustificationBox = styled.div`
  margin-left: 1rem;
  margin: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  max-width: 55%;

  > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #374954;
  }

  > p {
    word-break: break-all;
    white-space: normal;
    margin-top: 5px;
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const SendDocumentAgainBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  max-width: 400px;
  > p {
    word-break: break-all;
    white-space: normal;
  }

  svg {
    font-size: 1.5rem;
    cursor: pointer;
  }
`;
