import styled from "styled-components";

import ColoredSpan from "../../components/ColoredSpan";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Content = styled.div`
  width: 80vw;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const Span = styled(ColoredSpan)`
  @media (max-width: 1400px) {
    font-size: 0.8rem;
  }

  @media (max-width: 1080px) {
    font-size: 0.7rem;
  }
`;

export const UserTitleBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div span {
    margin-left: 1rem;
  }
`;

export const DocumentsNecessary = styled.div``;
export const UserNameTitle = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-top: 1.5rem;
  font-size: 1.5rem;
  color: var(--title);
`;

export const StepWarningMessage = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #7c878d;
  margin-top: 1.5rem;
  text-align: center;

  > strong {
    color: black;
  }
`;
