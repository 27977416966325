import { createGlobalStyle } from "styled-components";

import "react-toastify/dist/ReactToastify.css";

export default createGlobalStyle`
 * {
   margin: 0;
   padding: 0;
   box-sizing: 0; 
   outline: 0;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 14px;
  }
  ::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 4px;
    background-color: var(--primary);
  }

  :root {
  --white: #ffffff;
  --gray-100: #f6f6f6;
  --gray-200: #E5E5E5;
  --gray-300: #a8a8b3;
  --gray-400: #7a7a7a;
  --gray-500: #4a4a4a;
  --gray-600: #323238;
  --gray-700: #29292E;
  --gray-850: #121214;
  --gray-900: #09090A;

  --cyan-500: #61dafb;
  --yellow-500: #eba417;
  --primary: #650F40;
  --secondary: #DB9F1C;
  --turquey: #009B90;
  --title: #4a4a4a;
  --black: black;
  --alert:#ff0f0f;

  --toastify-color-success: #07bc0c;
}

@media (max-width: 1480px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 1080px) {
  html {
    font-size: 87.5%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 72.5%;
  }
}


 body {
  max-width: 100vw;
  background: var(--gray-100);
  color: var(--black);
   -webkit-font-smoothing: antialiased;
 }

 body, input, button {
   font-family: 'Roboto', serif;
   font-size: 16px;
 }

 h1, h2, h3, h4, h5, h6, strong {
   font-weight: 500
 }

 a {
   text-decoration: none;
 }

 button {
   cursor: pointer;
 }



 .PdfModal {
    position: absolute;
    top: 5%;
    left: 15%;
    right: 15%;

    bottom: 5%;
    overflow: auto;
    background: #Fff;
    transition: all 0.8s ease-in-out;
    max-height: 90vh;

    header {
      padding: 1rem;
      display: flex;
      height: 30px;
      align-items: center;
      justify-content: space-between;

      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        color: #374954;
      }

      svg {
        color: #374954;
        cursor: pointer;
        padding: 4px;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
          border-radius: 50%;
        }
      }
    }

    footer {
      display: flex;
      height: auto;
      background: #fff;
      align-items: center;
      justify-content: space-between;

    }
  }

  .ImageModal {
    position: absolute;
    top: 5%;
    left: 15%;
    right: 15%;
    bottom: auto;
    overflow: auto;
    background: #Fff;
    transition: all 0.8s ease-in-out;
    max-height: 90vh;


    header {
      padding: 1rem;
      display: flex;
      height: 30px;
      align-items: center;
      justify-content: space-between;

      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        color: #374954;
      }

      svg {
        color: #374954;
        cursor: pointer;
        padding: 4px;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
          border-radius: 50%;
        }
      }
    }

    footer {
      display: flex;
      height: auto;
      background: #fff;
      align-items: center;
      justify-content: space-between;
    }
  }

  .FlexModal {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .OnlyImageModal {
    position: absolute;
    top: 5%;
    left: 25%;
    bottom: 5%;
    overflow: auto;
    transition: all 0.8s ease-in-out;
  }

  .FlexBoxModal {
    position: absolute;
    top: 5%;
    left: 15%;
    right: 15%;
    bottom: 5%;
    overflow: auto;
    transition: all 0.8s ease-in-out;
    z-index: 1;
  }
  
  .Overlay {
    transition: all 0.8s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2;
  }

  .SentModal {
    position: absolute;
    top: 35%;
    left: 35%;
    right: 35%;
    bottom: 30%;
    background: #Fff;
    transition: all 0.8s ease-in-out;
  }    

  .p-dialog-mask.p-component-overlay {
    background-color: rgba(0,0,0,0.5);
  }

  .p-dialog .p-dialog-header-iconslog .p-dialog-header-icons {
    position: relative;
    top: 2rem;
    right: 1.5rem;
  }

  .p-dialog .p-dialog-header-icon {
    position: relative;
    top: 2rem;
    right: 1.5rem;
  }


  .pi-times {
    color: var(--primary);
    font-size: 24px;
  
    &:hover {
      filter: brightness(0.8);
      background-color: rgba(0,0,0,0.2);
      border-radius: 50%;
    }

  }

  #popup_menu {
    background: var(--primary);
    padding: 1rem;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    li {
      margin: .5rem 0;
      color: #fff ;
      font-weight: bold;
     a {
        color: var(--gray-300);
        font-weight: 400;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }

    
  }

`;
