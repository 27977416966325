import styled from "styled-components";
import { Timeline } from "primereact/timeline";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  > h5 {
    font-weight: 700;
    font-size: 2rem;
    align-items: center;
    color: var(--title);
  }

  > span {
    font-size: 1.2rem;
    color: var(--text-secondary);
  }

  .p-timeline-event-connector {
    background: var(--primary);
    height: 2px;
    width: 1px;
  }
`;

interface MarkerIconsProps {
  color: string;
}

export const MarkerIcon = styled.span<MarkerIconsProps>`
  background: ${({ color }) => color};
  padding: 0.5rem;
  border-radius: 50%;

  i {
    color: #fff;
  }
`;

interface EventCardProps {
  highlight: boolean;
}

export const EventCard = styled.div<EventCardProps>`
  flex-direction: column;
  background: ${({ highlight }) => (highlight ? "#fff" : "var(--gray-200)")};
  opacity: ${({ highlight }) => (highlight ? 1 : 0.8)};
  border-radius: 12px;
  padding: 0.8rem;
  box-shadow: ${({ highlight }) =>
    highlight ? "0px 0px 10px rgba(0, 0, 0, 0.1)" : "none"};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    width: 70vw;
  }

  header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;

    > div span {
      font-size: 1rem;
      color: var(--gray-300);
      white-space: nowrap;
    }

    label {
      font-weight: 700;
      font-size: 1.2rem;
      color: ${({ highlight }) =>
        highlight ? "var(--black)" : "var(--gray-600)"};
    }
  }

  > main {
    width: 100%;
    height: max-content;
    min-height: 3rem;
  }

  main p {
    font-size: 1rem;
    color: var(--title);
    padding: 0.5rem 0.25rem;
    text-align: start;
  }
`;

export const MobileTimeLine = styled(Timeline)`
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const DesktopTimeline = styled(Timeline)`
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
`;
