import { Dispatch, SetStateAction } from "react";
import { FiCheck } from "react-icons/fi";
import ReactModal from "react-modal";
import { CloseIcon, Container } from "./styles";

interface SentDocumentsModalProps {
  isOpen: boolean;
  setIsClosed: Dispatch<SetStateAction<boolean>>;
}

const SentDocumentsModal = ({
  isOpen,
  setIsClosed,
}: SentDocumentsModalProps) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className="SentModal"
      overlayClassName="Overlay"
      ariaHideApp={false}
      onRequestClose={() => setIsClosed(!isOpen)}
    >
      <Container>
        <FiCheck color="#54ad1d" size={30} />
        <span>Documentos enviados para análise</span>
        <div>
          <p>
            Seus documentos serão analisados e você será notificado quando essa
            ação for concluída. Enquanto isso, você pode acompanhar toda a
            movimentação pelo aplicativo
          </p>
        </div>
        {/* <Button onClick={() => setIsClosed(!isOpen)}>ACOMPANHAR AÇÃO</Button> */}
        <CloseIcon
          size={30}
          color="var(--primary)"
          onClick={() => setIsClosed(!isOpen)}
        />
      </Container>
    </ReactModal>
  );
};

export default SentDocumentsModal;
