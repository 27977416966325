import styled, { keyframes } from "styled-components";

import signInBackground from "../../assets/sign-in-background.png";
import Button from "../../components/Button";
import Input from "../../components/Input";

export const Container = styled.div`
  height: calc(100vh - 4rem);
  display: flex;
  align-items: stretch;
  min-width: 800px;
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackground}) no-repeat center;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  img {
    height: 4.5rem;
    width: auto;
  }
`;

export const BackgroundContent = styled.div`
  background: rgb(33, 43, 49, 0.5);
  width: 18.75rem;
  padding: 1.625rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20%;

  > h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 140%;
    color: #ffffff;
    margin-top: 1.125rem;
    opacity: 1;
  }

  span {
    opacity: 1;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 140%;
    color: #ffffff;
    margin-top: 0.5rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 50%;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(100px);
  } to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  margin-top: 20%;

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 1rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    line-height: 140%;
    color: #374954;

    > div {
      margin-top: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
      place-items: center;
      > img {
        cursor: pointer;
      }
    }
  }
`;

interface LoginContentProps {
  step: number;
}

export const LoginCotent = styled.div<LoginContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${appearFromLeft} 1s;
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: 21.875rem;
  padding: 2rem 1rem;

  > h1 {
    font-family: Alegreya;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #7c878d;
  }

  > div {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 2rem;

    > label {
      text-align: ${({ step }) => (step === 1 ? "center" : "start")};
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 140%;
      color: #374954;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
    }
  }

  > span {
    margin-top: 1.5rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #7c878d;
  }
`;

export const SmsCodeButtonsBox = styled.div`
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    > span {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 140%;
      text-decoration-line: underline;
      color: var(--primary);
      cursor: pointer;
      &:hover {
        filter: brightness(0.7);
      }
    }
  }
`;

export const LoginInput = styled(Input)`
  align-items: flex-start;
  padding: 0.75rem 1rem;
  background: #ffffff;
  width: 100%;
  border: 1px solid #aab6bc;
  box-sizing: border-box;
  border-radius: 4.8px;
`;

export const ContinueButton = styled(Button)`
  margin-top: 2rem;
  background: var(--primary);
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: none;
  width: 100%;
  height: 3rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #ffffff;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const SmsInputBox = styled.div`
  display: flex;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  place-items: center;
  margin: 1rem auto;

  > input {
    display: flex;
    padding: 0.75rem;
    width: 3rem;
    height: 3.5rem;
    border: 1px solid #aab6bc;
    box-sizing: border-box;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 120%;
    color: #374954;
    background: #eaedef;

    &:focus {
      border: 1px solid #a9c838;
    }
  }
`;

export const SmsInput = styled(Input)``;
