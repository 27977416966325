import styled from "styled-components";
import { ImExit } from "react-icons/im";

export const Container = styled.div`
  background: var(--primary);
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
`;

export const Content = styled.div`
  background: var(--primary);
  height: 100%;
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ExitIcon = styled(ImExit)`
  cursor: pointer;
  margin-left: 2rem;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    margin-right: 0.5rem;
    white-space: nowrap;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: right;
    color: var(--gray-200);
  }

  > img {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    object-fit: contain;
  }

  > div {
    margin-right: 16px;
  }

  > h3 {
    white-space: nowrap;
    margin-left: 1rem;
    font-family: Roboto;
    font-size: 1rem;
    display: flex;
    align-items: center;
    text-align: right;
    color: var(--secondary);
    cursor: pointer;
    padding: 0.4rem 0.8rem;
    border: 2px solid var(--secondary);
    border-radius: 2rem;
    transition: all 0.2s;

    &:hover {
      background: var(--secondary);
      color: #fff;
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    cursor: pointer;
    width: auto;
    height: 3rem;
    &:hover {
      filter: brightness(0.8);
    }
  }

  > div {
    cursor: pointer;
    margin-left: 0.938rem;
    display: flex;
    border-radius: 0.25rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    transition: background 0.2 ease-in-out;
    &:hover {
      background: #d8d8d8;
    }

    > span {
      margin-left: 5px;
      white-space: nowrap;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 140%;
      display: flex;
      align-items: center;
      text-align: center;
      color: #374954;
    }

    > i {
      font-size: 1.5rem;
      color: #374954;
    }
  }
`;

export const SidebarCotent = styled.div`
  background: #ffff;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  > img {
    color: var(--primary);
    margin-top: 1.875rem;
  }

  > svg {
    cursor: pointer;
    min-height: 1.5rem;
    min-width: 1.5rem;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const PagesDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  font-size: 1.125rem;
  margin-bottom: 150%;

  div {
    margin-top: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: box-shadow 0.6s;
    border-radius: 4px;

    &:hover {
      box-shadow: inset 4.5em 0 0 0 var(--primary);
      cursor: pointer;
      span {
        font-weight: bold;
        /* color: #fff; */
      }
    }
  }
`;

export const StagingText = styled.h1`
  font-family: "Roboto", sans-serif;
  margin-left: 3.125rem;
  color: var(--primary);
  font-size: 2rem;
`;
