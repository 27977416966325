import styled from "styled-components";
import ColoredSpan from "../../../../../components/ColoredSpan";

export const Container = styled.div`
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
    background: var(--gray-200);
  }
  padding: 1.5rem;
  display: block;

  border: 1px solid rgba(0, 155, 144, 0.3);
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
      flex-direction: row;
      svg {
        margin: 0 0.5rem;
        color: #374954;
      }
    }
  }
`;

interface ReadMessageTextProps {
  read: boolean;
}

export const ReadIssueText = styled.span<ReadMessageTextProps>`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${({ read }) => (read ? "var(--secondary)" : "var(--gray-500)")};
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const LastReplyDate = styled.span`
  color: var(--gray-500);
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

export const Title = styled.strong`
  color: var(--black);
  margin-top: 0.5rem;
  display: block;
`;

export const Description = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 1rem 0;
  background: rgba(0, 155, 144, 0.3);
  padding: 0.8rem;
  border-radius: 12px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--gray-700);
`;

export const Type = styled(ColoredSpan)`
  margin: 0 0.5rem;
`;
