import { FiX } from "react-icons/fi";
import styled from "styled-components";
import AppButton from "../Button";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  flex-direction: column;

  > span {
    color: #54ad1d;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    line-height: 23px;
    margin: 1rem;
  }

  > div {
    width: 80%;
    > p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: #374954;
      text-align: center;
      margin: 1rem;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 1rem;
`;

export const CloseIcon = styled(FiX)`
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.2s;
  margin: 1rem;

  &:hover {
    filter: brightness(0.8);
    background: rgb(128, 128, 128, 0.8);
    border-radius: 50%;
  }
`;

export const Button = styled(AppButton)`
  background: #fff;
  border: 2px solid var(--primary);
  width: 90%;
  color: var(--primary);
  font-weight: bold;
  margin: 1rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: var(--primary);
    color: #fff;
  }
`;
