import { HelpApi } from "./HelpApi";

interface CreateHelpService {
  name?: string;
  email?: string;
  subject: string;
  message: string;
}

const CreateHelp = async ({
  email,
  message,
  name,
  subject,
}: CreateHelpService) => {
  const response = await HelpApi.SendHelpMail({
    email,
    message,
    name,
    subject,
  });
  return response;
};

export const HelpService = {
  CreateHelp,
};
