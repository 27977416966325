import { useState } from "react";
import { FiChevronLeft, FiSearch } from "react-icons/fi";
import ColoredSpan from "../../../../components/ColoredSpan";
import { Issue as IssueProps, IssueTypescolors } from "../../../../types/issue";
import Issue from "./Issue";
import IssueDetailed from "./IssueDetailed";
import { IssuesMock } from "./issueMock";

import {
  Container,
  Content,
  GoBackButton,
  SearchInputBox,
  Title,
} from "./styles";

interface SidebarProps {
  changeSelectedIssue: (issue: any) => void;
  issueSelected?: IssueProps | null;
}

const Sidebar = ({ changeSelectedIssue, issueSelected }: SidebarProps) => {
  const [searchIssues, setSearchIssues] = useState(IssuesMock);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;
    const filteredIssues = IssuesMock.filter((issue) =>
      issue.title.toLowerCase().includes(search.toLowerCase())
    );
    setSearchIssues(filteredIssues);
  };

  return (
    <Container issueSelected={!!issueSelected}>
      {issueSelected ? (
        <header>
          <GoBackButton onClick={() => changeSelectedIssue(null)}>
            <FiChevronLeft size={24} color="var(--secondary)" />
            <span>Voltar</span>
          </GoBackButton>
          <h1>{issueSelected.title}</h1>
          <ColoredSpan color={IssueTypescolors[issueSelected.type]}>
            {issueSelected.type}
          </ColoredSpan>
        </header>
      ) : (
        <header>
          <Title>Historico de Mensagens</Title>
          <SearchInputBox>
            <FiSearch />
            <input placeholder="Procurar" onChange={handleSearch} />
          </SearchInputBox>
        </header>
      )}

      <Content>
        {issueSelected ? (
          <IssueDetailed issue={issueSelected} />
        ) : (
          searchIssues.map((issue) => {
            return (
              <Issue
                key={issue.id}
                issue={issue}
                onClick={() => changeSelectedIssue(issue)}
              />
            );
          })
        )}
      </Content>
    </Container>
  );
};

export default Sidebar;
