import api from "../api";

interface SendHelpMailProps {
  name?: string;
  email?: string;
  subject: string;
  message: string;
}

export const SendHelpMail = async ({
  email,
  name,
  message,
  subject,
}: SendHelpMailProps) => {
  const { data } = await api.post(`help`, {
    email,
    name,
    message,
    subject,
  });

  return data;
};

export const HelpApi = { SendHelpMail };
