import {
  Container,
  EventCard,
  MarkerIcon,
  MobileTimeLine,
  DesktopTimeline,
} from "./styles";
import { TimelineEvent, TimelineProps } from "./types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import remarkYamlConfig from "remark-yaml-config";
import remarkParse from "remark-parse";
import remarkFrontmatter from "remark-frontmatter";
import remarkCommentConfig from "remark-comment-config";
import ReactLoading from "react-loading";
import { useQuery } from "react-query";
import { CausesService } from "../../services/Causes/CausesService";
import { parseDate } from "../../utils/formatDate";
import { useParams } from "react-router-dom";

interface RouteParams {
  id?: string;
}

const Timeline = ({ causeName, onCardClick }: TimelineProps) => {
  let { id = "0" } = useParams<RouteParams>();
  const { data, isLoading } = useQuery("CauseAccompanimentGetList", () =>
    CausesService.getStageOfCause(id, "1", "10")
  );

  const customizedMarker = (item: TimelineEvent) => {
    return (
      <MarkerIcon
        color={item.status === "success" ? "var(--primary)" : "var(--gray-200)"}
      />
    );
  };

  const customizedContent = (item: TimelineEvent) => {
    return (
      <EventCard
        highlight={item.status === "success"}
        onClick={() => onCardClick(item)}
      >
        <header>
          <label>{item.title} </label>
          <div>
            <span>
              {" "}
              {item.status === "success" ? parseDate(item?.updated_at) : null}
            </span>
          </div>
        </header>
        <main onClick={() => console.log("clicou no evento")}>
          <ReactMarkdown
            children={item.content}
            remarkPlugins={[
              remarkGfm,
              remarkBreaks,
              remarkYamlConfig,
              remarkParse,
              remarkFrontmatter,
              remarkCommentConfig,
            ]}
          />
        </main>
      </EventCard>
    );
  };

  if (isLoading) {
    <Container>
      <ReactLoading
        type="bubbles"
        color={"var(--primary)"}
        height="72px"
        width="72px"
      />
    </Container>;
  }

  return (
    <Container>
      <span>{causeName}</span>
      <DesktopTimeline
        value={data}
        align="alternate"
        marker={customizedMarker}
        content={customizedContent}
      />
      <MobileTimeLine
        value={data}
        align="right"
        marker={customizedMarker}
        content={customizedContent}
      />
    </Container>
  );
};

export default Timeline;
