import styled from "styled-components";
import Button from "../../components/Button";
import AppInput from "../../components/Input";

export const Container = styled.div``;

export const Input = styled(AppInput)`
  align-items: flex-start;
  padding: 0.75rem 1rem;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4.8px;
`;

export const UpdateMailModalContent = styled.div`
  display: flex;
  margin-top: 25vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background: #fff;
  border-radius: 12px;
  min-width: 300px;
  max-width: 500px;
  > div {
    margin: 1rem 0;
    width: 100%;
  }

  h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 23px;
  }

  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: var(--gray-300);
    margin-top: 0.7rem;
  }
`;

export const ModalSubmitButton = styled(Button)`
  background: var(--primary);
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: none;
  width: 100%;
  height: 3rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #ffffff;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;
