import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import CauseDataCard from "../../components/CauseDataCard";
import Topbar from "../../components/Topbar";
import { CausesService } from "../../services/Causes/CausesService";
import { Accordion, AccordionTab } from "primereact/accordion";
import history from "../../services/history";
import ReactLoading from "react-loading";

import {
  CauseMediaList,
  CauseTitle,
  Container,
  Content,
  Media,
  AccompanimentButton,
  DocumentationButton,
} from "./styles";
import { toast } from "react-toastify";
import { VscOpenPreview } from "react-icons/vsc";
import { HiOutlineDocumentText } from "react-icons/hi";
import BreadCrumb from "../../components/BreadCrumb";
import { BreadCrumbNavItem } from "../../components/BreadCrumb/types";

interface RouteParams {
  id?: string | undefined;
}

const CauseDetails: React.FC = () => {
  const [cardIsOpen, setCardIsOpen] = useState(true);
  let { id } = useParams<RouteParams>();

  const breadCrumbNavItems: BreadCrumbNavItem[] = [
    { label: "Causa", url: `/cause/${id}` },
  ];

  const { data, isLoading } = useQuery(
    "CausesServiceGetList",
    () => CausesService.getCause(Number(id)),
    {
      onError: () => {
        history.goBack();
        toast.error("Não foi possível encontrar essa causa");
      },
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return (
      <Container>
        <Topbar />
        <ReactLoading
          type="bubbles"
          color="var(--primary)"
          height="46px"
          width="46px"
        />
      </Container>
    );
  }

  return (
    <Container>
      <Topbar />
      <BreadCrumb navItems={breadCrumbNavItems} />
      <Content>
        <header>
          <div>
            <span>{data?.demand?.title}</span>
          </div>
          <aside>
            <AccompanimentButton to={`/cause/accompaniment/${id}`}>
              <VscOpenPreview size={30} />
              Acompanhamento
            </AccompanimentButton>
            <DocumentationButton to={`/documents/${id}`}>
              <HiOutlineDocumentText size={30} /> Documentação
            </DocumentationButton>
          </aside>
        </header>

        <CauseDataCard
          isOpen={cardIsOpen}
          onClick={setCardIsOpen}
          payment={data?.payment}
          demand={data?.demand}
          contract={data?.contract}
          createdAt={data?.created_at}
        />

        <>
          <Accordion>
            <AccordionTab
              header={
                <>
                  <i className="pi pi-info-circle"></i>
                  <span>{data?.demand?.title} </span>
                </>
              }
            >
              <CauseTitle>
                <label>Codigo da demanda:</label>
                <p>{data?.demand_code}</p>
              </CauseTitle>
              <CauseTitle style={{ marginTop: "1rem" }}>
                <label>Informações:</label>
                <p>{data?.demand?.information}</p>
              </CauseTitle>
              {data?.demand?.videos.length! > 0 ? (
                <CauseTitle style={{ marginTop: "1rem" }}>
                  <label> Videos:</label>
                  <CauseMediaList>
                    {data?.demand?.videos.map((video) => (
                      <>
                        <Media
                          key={video.id}
                          frameBorder="0"
                          allow="autoplay; encrypted-media"
                          allowFullScreen
                          src={`https://www.youtube.com/embed/${video.youtube_code}`}
                          title={`${video.name}`}
                        />
                      </>
                    ))}
                  </CauseMediaList>
                </CauseTitle>
              ) : null}
            </AccordionTab>
          </Accordion>
        </>
      </Content>
    </Container>
  );
};

export default CauseDetails;
