import React from "react";
import { Switch } from "react-router-dom";
import SignIn from "../pages/SignIn";
import Documents from "../pages/DocumentsUpload";
import ResetPassword from "../pages/ResetPassword";
import Notification from "../pages/Notifications";
import SignUpWithToken from "../pages/SignUpWithToken";
import Route from "./Route";
import Causes from "../pages/Causes";
import SignUp from "../pages/SignUp";
import CauseAccompaniment from "../pages/CauseAccompaniment";
import CauseDetails from "../pages/CauseDetails";
import MessagesDiscussion from "../pages/MessagesDiscussion";
import CpfCheck from "../pages/CpfCheck";
import Contract from "../pages/Contract";

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} hasHelpButton />
    <Route path="/signup" exact component={SignUp} hasHelpButton />
    <Route
      path="/checkcpf"
      exact
      component={CpfCheck}
      isPrivate
      hasHelpButton
    />
    <Route
      path="/contract"
      exact
      component={Contract}
      isPrivate
      hasHelpButton
    />
    <Route
      path="/documents/:id"
      isPrivate
      exact
      component={Documents}
      hasHelpButton
    />
    <Route
      path="/documents"
      isPrivate
      exact
      component={Documents}
      hasHelpButton
    />
    <Route
      path="/redefine-password"
      exact
      component={ResetPassword}
      hasHelpButton
    />
    <Route path="/notifications" exact component={Notification} isPrivate />
    <Route
      path="/cause/accompaniment/:id"
      exact
      component={CauseAccompaniment}
      isPrivate
    />
    <Route path="/causes" exact component={Causes} isPrivate hasHelpButton />
    <Route
      path="/cause/:id"
      exact
      component={CauseDetails}
      isPrivate
      hasHelpButton
    />
    <Route
      path="/messages"
      exact
      component={MessagesDiscussion}
      isPrivate
      hasHelpButton
    />
    <Route path="/redirect-login" exact component={SignUpWithToken} />
  </Switch>
);
export default Routes;
