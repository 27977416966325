import { profileUpdate } from "../../types/user";
import { ProfileApi } from "./ProfileApi";

const UpdateMail = async ({
  email,
  password,
  reset_password,
}: profileUpdate) => {
  const response = await ProfileApi.UpdateMail({
    email,
    password,
    reset_password,
  });
  return response;
};

const CheckCpf = async (cpf: string) => {
  const response = await ProfileApi.CheckCpf(cpf);
  return response;
};

const CheckExistsCpfInClientIdentity = async () => {
  const { data } = await ProfileApi.getClient();
  return data?.client_identity?.cpf;
};

export const ProfileService = {
  UpdateMail,
  CheckExistsCpfInClientIdentity,
  CheckCpf,
};
