import React from "react";
import { AnimationContainer, Background, Container, Content } from "./styles";

//assets
import appleStore from "../../assets/available-apple-store.png";
import googleStore from "../../assets/available-google-store.png";
import Topbar from "../../components/Topbar";
import EmailLogin from "../../components/Login/Email";

const SignIn: React.FC = () => {
  return (
    <>
      <Topbar />
      <Container>
        <Background />
        <Content>
          <AnimationContainer>
            <>
              <EmailLogin />
              <footer>
                Não tem cadastro?
                <br />
                Baixe o app e faça o seu cadastro
                <div>
                  <a href="https://apps.apple.com/br/app/birodigital/id1570370423">
                    <img src={appleStore} alt="Available Apple Store" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.biroapp">
                    <img src={googleStore} alt="Google Play Store" />
                  </a>
                </div>
              </footer>
            </>
          </AnimationContainer>
        </Content>
      </Container>
    </>
  );
};

export default SignIn;
