import { Container, Content } from "./styles";
import { BreadCrumbProps } from "./types";
import { Link } from "react-router-dom";
import { FiHome } from "react-icons/fi";
import { FaAngleRight } from "react-icons/fa";

const BreadCrumb = ({ navItems = [] }: BreadCrumbProps) => {
  return (
    <Container>
      <Content>
        <Link to={"/causes"}>
          <FiHome size={20} color="black" />
          <FaAngleRight size={20} style={{ margin: "0 .3rem" }} />
        </Link>
        {navItems?.map((item, index) =>
          index === navItems.length - 1 ? (
            <span key={index}>{item.label}</span>
          ) : (
            <Link key={index} to={`${item.url}`}>
              <span>{item.label}</span>
              <FaAngleRight size={20} style={{ margin: "0 .3rem" }} />
            </Link>
          )
        )}
      </Content>
    </Container>
  );
};

export default BreadCrumb;
