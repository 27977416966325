import React from "react";

import { Container } from "./styles";

interface TooltipProps {
  title: string;
  bgColor?: string;
  color?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  children,
  bgColor,
  color,
}) => {
  return (
    <Container bgColor={bgColor} color={color}>
      {children}
      <span>{title}</span>
    </Container>
  );
};

export default Tooltip;
