import { Archive, Document } from "../../../types/document";
import {
  Container,
  JustificationBox,
  ModalContent,
  SendButton,
  SendDocumentAgainBox,
  UploadedFileBox,
  UploadedFileStatusMessage,
  ViewAndRemoveBox,
  ViewAndRemoveButton,
} from "./styles";
import { FiCheck, FiUpload, FiX, FiTrash2 } from "react-icons/fi";
import { AiOutlinePicture } from "react-icons/ai";
import { useRef, useState } from "react";
import { UploadImageService } from "../../../services/UploadImage/UploadImageService";
import { useMutation, useQueryClient } from "react-query";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import ReactModal from "react-modal";
import { documentStatusTranslated } from "../../../utils/formatSpanShadow";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import SentDocumentsModal from "../../SentDocumentsModal";

interface DocumentUploadProps {
  document: Document;
  causeId: any;
  necessaryId: number;
  archiveData: Archive;
  highlighted: boolean;
}

const DocumentUpload = ({
  document,
  causeId,
  necessaryId,
  archiveData,
  highlighted,
}: DocumentUploadProps) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [completedModalIsOpen, setCompletedModalIsOpen] =
    useState<boolean>(false);
  const [file, setFile] = useState<any>();
  const queryClient = useQueryClient();
  const uploadMutate = useMutation(
    "UploadImageService",
    (formData: any) =>
      UploadImageService.uploadImage(formData, causeId, necessaryId),
    {
      onSuccess: (data) => {
        if (
          data.data.cause.step === "documentation" &&
          data.data.cause.status === "analyze"
        )
          setCompletedModalIsOpen(true);
        toast.success("Documento enviado com sucesso");
        queryClient.invalidateQueries("CausesServiceGetList");
      },
      onError: (data) => handleErrorUpload(data),
    }
  );

  const deleteMutate = useMutation(
    "DeleteImageService",
    () => UploadImageService.deleteImage(document?.archive?.id!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("CausesServiceGetList");
        toast.success("Documento deletado com sucesso");
        setFile(null);
      },
      onError: (data) => handleErrorUpload(data),
    }
  );

  function handleErrorUpload(data: any) {
    toast.error(data.response.data.impossible_create_document[0]);
  }

  function handleUpload(fileType: any) {
    const formData = new FormData();
    formData.append("file", file.selectedFile, file.selectedFile.name);
    formData.append("document_necessary_id", `${necessaryId}`); // cpf junto ao formData.
    if (fileType) {
      if (formatIsAllowed(fileType, file?.selectedFile?.name)) {
        uploadMutate.mutate(formData);
        setFile(null);
        setModalIsOpen(false);
      } else {
        toast.error("Formato não permitido para esse documento");
      }
    }
  }

  function onChangefile(event: any) {
    setFile({ selectedFile: event.target.files[0] });
  }

  function formatIsAllowed(arrayOfFormats: string[], fileFormat: string) {
    const parsedArray: string[] = [];
    arrayOfFormats.forEach((item) => {
      parsedArray.push(item.slice(1));
    });
    const format = fileFormat.split(".");

    if (parsedArray.find((item) => item === format[format.length - 1]))
      return true;

    return false;
  }

  function handleSendOrViewButton(archiveData: Archive) {
    if (archiveData?.url)
      return (
        <UploadedFileBox>
          {archiveData?.status === "aproved" ? (
            <ViewAndRemoveBox>
              <UploadedFileStatusMessage color={"#54AD1D"}>
                Documento Aprovado
              </UploadedFileStatusMessage>
              <ViewAndRemoveButton onClick={() => setModalIsOpen(!modalIsOpen)}>
                Visualizar <AiOutlinePicture size={25} />{" "}
              </ViewAndRemoveButton>
              <FiCheck color="#54ad1d" size={20} />
            </ViewAndRemoveBox>
          ) : archiveData?.status === "reproved" ? (
            <ViewAndRemoveBox>
              <UploadedFileStatusMessage color={"#A6042B"}>
                Documento reprovado
              </UploadedFileStatusMessage>
              <ViewAndRemoveButton onClick={() => setModalIsOpen(!modalIsOpen)}>
                Visualizar <AiOutlinePicture size={25} />
              </ViewAndRemoveButton>
            </ViewAndRemoveBox>
          ) : (
            <ViewAndRemoveBox>
              <UploadedFileStatusMessage color={"#1D7AAD"}>
                Documento enviado. Aguardando aprovação
              </UploadedFileStatusMessage>
              <ViewAndRemoveButton onClick={() => setModalIsOpen(!modalIsOpen)}>
                Visualizar <AiOutlinePicture size={25} />
              </ViewAndRemoveButton>
              <ViewAndRemoveButton onClick={() => deleteMutate.mutate()}>
                Remover <FiTrash2 size={25} />
              </ViewAndRemoveButton>
            </ViewAndRemoveBox>
          )}
        </UploadedFileBox>
      );

    return (
      <SendButton loading={uploadMutate.isLoading}>
        <div onClick={() => inputFile?.current?.click()}>
          Enviar arquivo
          <FiUpload size={20} />
          <input
            type="file"
            id="file"
            ref={inputFile}
            onChange={(event: any) => onChangefile(event)}
            style={{ display: "none" }}
          />
        </div>
      </SendButton>
    );
  }

  return (
    <Container highlighted={highlighted}>
      <span>{document.field_name}</span>
      <div>
        {file ? (
          <>
            {uploadMutate.isLoading ? (
              <ReactLoading
                type="bubbles"
                color="var(--primary)"
                height="56px"
                width="70px"
              />
            ) : (
              <>
                <p>
                  Deseja enviar <strong>{file.selectedFile.name}</strong>?
                </p>
                <FiX color="#a6042b" size={30} onClick={() => setFile(null)} />
                <FiCheck
                  color="#54ad1d"
                  size={30}
                  onClick={() => handleUpload(document?.extension)}
                />
              </>
            )}
          </>
        ) : (
          handleSendOrViewButton(archiveData)
        )}
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        className={`${
          document?.archive?.content_type?.search("pdf")! > 0
            ? "PdfModal"
            : "ImageModal"
        }`}
        overlayClassName="Overlay"
        onRequestClose={() => setModalIsOpen(!setModalIsOpen)}
        ariaHideApp={false}
      >
        <header>
          <span>{document?.title}</span>
          {documentStatusTranslated[document?.archive?.status!]}
          <FiX size={20} onClick={() => setModalIsOpen(!setModalIsOpen)} />
        </header>
        <ModalContent expandContent={document?.archive?.status !== "reproved"}>
          {document?.archive?.content_type?.search("pdf")! > 0 ? (
            <embed src={document?.archive?.url} />
          ) : (
            <TransformWrapper>
              <TransformComponent contentStyle={{ width: "100%" }}>
                <img src={document?.archive?.url} alt="Documento" />
              </TransformComponent>
            </TransformWrapper>
          )}
        </ModalContent>
        {document?.archive?.status === "reproved" ? (
          <>
            <footer>
              <JustificationBox>
                <h2>Justificativa:</h2>
                <p>{document?.archive?.justification}</p>
              </JustificationBox>
              {file ? (
                <SendDocumentAgainBox>
                  <p>
                    Deseja enviar <strong>{file.selectedFile.name}</strong>?
                  </p>
                  <FiX
                    color="#a6042b"
                    size={35}
                    onClick={() => setFile(null)}
                  />
                  <FiCheck
                    color="#54ad1d"
                    size={35}
                    onClick={() => handleUpload(document.extension)}
                  />
                </SendDocumentAgainBox>
              ) : (
                <SendButton>
                  <div onClick={() => inputFile?.current?.click()}>
                    Enviar novamente
                    <input
                      type="file"
                      id="file"
                      ref={inputFile}
                      onChange={(event: any) => onChangefile(event)}
                      style={{ display: "none" }}
                    />
                  </div>
                </SendButton>
              )}
            </footer>
          </>
        ) : null}
      </ReactModal>
      {
        <SentDocumentsModal
          isOpen={completedModalIsOpen}
          setIsClosed={setCompletedModalIsOpen}
        />
      }
    </Container>
  );
};

export default DocumentUpload;
