import { FiHelpCircle, FiMail } from "react-icons/fi";

import { Button, Container, HelpForm, Input } from "./styles";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef, useState } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import { useMutation } from "react-query";
import { HelpService } from "../../services/Help/HelpService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const HelpButton = ({ isPrivate }) => {
  const op = useRef(null);
  const formRef = useRef(null);
  const textAreaValue = useRef();
  const [selectValue] = useState("Entrar no Site");
  const user = useSelector((state) => state.user.profile);
  const [onMouseOver, setOnMouseOver] = useState(false);

  const { mutate, isLoading } = useMutation(
    "HelpSendEmail",
    HelpService.CreateHelp,
    {
      onSuccess: (data) => {
        toast.success(data?.message?.send_email_success[0]);
      },
    }
  );

  const handleSubmit = async (data) => {
    try {
      if (!isPrivate) {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email("Digite um e-mail válido")
            .required("E-mail obrigatório"),
          name: Yup.string().required("Nome obrigatório"),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        mutate({
          ...data,
          subject: selectValue,
          message: textAreaValue.current.value,
        });
      } else {
        mutate({
          email: user?.email,
          name: user?.name,
          subject: selectValue,
          message: textAreaValue.current.value,
        });
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
    }
  };

  return (
    <>
      <Container
        onClick={(e) => op.current?.toggle(e)}
        MouseIsOver={onMouseOver}
        onMouseOver={() => setOnMouseOver(true)}
        onMouseOut={() => setOnMouseOver(false)}
      >
        <FiHelpCircle size={36} color="#fff" />
        <span>Ajuda</span>
      </Container>
      <OverlayPanel ref={op}>
        <HelpForm>
          <h1>Central de ajuda</h1>

          <Form ref={formRef} onSubmit={handleSubmit}>
            {!isPrivate && (
              <>
                <Input
                  label="E-mail"
                  labelColor="var(--gray-300)"
                  name="email"
                  icon={FiMail}
                  placeholder="E-mail"
                  background="#fff"
                  height="2rem"
                />
                <Input
                  label="Nome"
                  labelColor="var(--gray-300)"
                  name="name"
                  placeholder="Nome"
                  background="#fff"
                  height="2rem"
                />
              </>
            )}
            {/* <label style={{ marginTop: ".5rem" }}>Selecione o assunto</label>
            <select onChange={(e) => setSelectValue(e.target.value)}>
              <option>Entrar no Site</option>
              <option>Enviar Documentos</option>
              <option>Conseguir os Documentos</option>
              <option>Outro</option>
            </select> */}

            <textarea
              placeholder="Digite aqui sua mensagem"
              ref={textAreaValue}
            />

            <Button loading={isLoading} type="submit">
              Enviar
            </Button>
          </Form>
        </HelpForm>
      </OverlayPanel>
    </>
  );
};

export default HelpButton;
