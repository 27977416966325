import { FiArrowLeft } from "react-icons/fi";
import { TimelineEvent } from "../../../../components/Timeline/types";
import { parseDate } from "../../../../utils/formatDate";

import { Container, Content } from "./styles";

interface AccompanimentDetailsProps {
  onBack: (acompaniment: any) => void;
  acompaniment: TimelineEvent;
}

const AccompanimentDetails = ({
  onBack,
  acompaniment,
}: AccompanimentDetailsProps) => {
  const { content, title, created_at } = acompaniment;

  return (
    <Container>
      <header onClick={() => onBack(null)}>
        <FiArrowLeft size={24} />
        <span>Voltar</span>
      </header>
      <div>
        <span>{title}</span>
        <span>{parseDate(created_at)}</span>
      </div>
      <Content>{content}</Content>
    </Container>
  );
};

export default AccompanimentDetails;
