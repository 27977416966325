import React from "react";
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from "react-router-dom";
import HelpButton from "../components/HelpButton";
import { store } from "../store";

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  hasHelpButton?: boolean;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  hasHelpButton,
  ...rest
}) => {
  const { signed } = store.getState().auth;
  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!signed ? (
          <>
            <Component />
            {hasHelpButton ? <HelpButton isPrivate={isPrivate} /> : null}
          </>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? "/" : "causes",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;

