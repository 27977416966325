import axios from "axios";
import { store } from "../store";
import { signOut } from "../store/modules/auth/actions";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
    ? process.env.REACT_APP_BASE_URL
    : "https://api-biro-prd.herokuapp.com/api/v1/",
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      store.dispatch(signOut());
    }
    return error;
  }
);

export default api;
